import React, {useState} from 'react';
import {CalendarOutlined, DownOutlined} from '@ant-design/icons';
import {Button, Dropdown, message, Space} from 'antd';


const yearItems = [
    {
        label: '2022',
        key: '2022',
        icon: <CalendarOutlined/>,
    },
    {
        label: '2023',
        key: '2023',
        icon: <CalendarOutlined/>,
    },
    {
        label: '2024',
        key: '2024',
        icon: <CalendarOutlined/>,
    },
    {
        label: '2025',
        key: '2025',
        icon: <CalendarOutlined/>,
    },
];


const DropDownMenuYear = ({onYearChange}) => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

    const handleYearMenuClick = (e) => {
        const selectedYear = yearItems.find(item => item.key === e.key).label;
        setSelectedYear(selectedYear);
        onYearChange(selectedYear);
    };

    const yearMenuProps = {
        items: yearItems,
        onClick: handleYearMenuClick,
    };


    return (
        <Space wrap>
            <Dropdown menu={yearMenuProps}>
                <Button>
                    <Space>
                        {selectedYear}
                        <DownOutlined/>
                    </Space>
                </Button>
            </Dropdown>
        </Space>
    );
};

export default DropDownMenuYear;
