import {useSortable} from '@dnd-kit/sortable';
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {useCookies} from 'react-cookie';
import Draggable from 'react-draggable';
import '../projectPage.css';

import {CSS} from '@dnd-kit/utilities';
import {
    AutoComplete,
    Badge,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Descriptions,
    Dropdown,
    Flex,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Skeleton,
    Space,
    Steps,
    Table,
    Tag,
    Timeline,
    Typography
} from "antd";
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExportOutlined,
    EyeOutlined,
    FileSearchOutlined,
    FolderViewOutlined,
    FormOutlined,
    FundViewOutlined,
    PercentageOutlined,
    PlusOutlined,
    SaveOutlined,
    ShoppingOutlined,
    SyncOutlined,
    ToolOutlined,
    UploadOutlined
} from "@ant-design/icons";

import dayjs from 'dayjs';
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import commandIcon from "../icons/icons8-command-96.png";
import fIcon from "../icons/icons8-f-key-96.png";
import ctrlIcon from "../icons/icons8-ctrl-96.png";
import AddressAutocomplete from "../components/AddressAutocomplete";
import ProjectMemoList from "../components/ProjectMemoList";
import FileManager from "../components/FileManager";


dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

const {Link, Text} = Typography;
const {Meta} = Card;
const {Option} = Select;
const {TextArea} = Input;


const vendorOptions = [
    {value: 'KINGS ELEVATOR PARTS INC.', label: 'KINGS ELEVATOR PARTS INC.'},
    {value: 'ZZIPCO', label: 'ZZIPCO'},
    {value: 'FLAME CUT STEEL INC.', label: 'FLAME CUT STEEL INC.'},
    {value: 'MEGA ELEVATOR PARTS', label: 'MEGA ELEVATOR PARTS'},
    {value: 'XPRESS DOOR-N-CABS', label: 'XPRESS DOOR-N-CABS'},
    {value: 'TANNER BOLT N NUT INC.', label: 'TANNER BOLT N NUT INC.'},
    {value: 'HOLLISTER WHITNEY', label: 'HOLLISTER WHITNEY'},
    {value: 'GAL', label: 'GAL'},
    {value: 'ESI', label: 'ESI'},
    {value: 'PELLE', label: 'PELLE'},
    {value: 'THE HOME DEPOT', label: 'THE HOME DEPOT'},
    {value: 'Amazon', label: 'Amazon'},
    {value: 'VANTAGE ELEVATION', label: 'VANTAGE ELEVATION'},
    {value: 'Smartrise Engineering', label: 'Smartrise Engineering'},
    {value: 'Canton Elevator', label: 'Canton Elevator'},
    {value: 'Kwong Ming Lighting n Furnature Inc.', label: 'Kwong Ming Lighting n Furnature Inc.'},
    {value: 'Lendy Electric', label: 'Lendy Electric'},
    {value: 'Elevator Products Corp', label: 'Elevator Products Corp'},
    {value: 'DBA JANUS ELEVATOR PRODUCTS', label: 'DBA JANUS ELEVATOR PRODUCTS'},
    {value: 'Giant Lift', label: 'Giant Lift'},
    {value: 'PFLOW INDUSTRIES', label: 'PFLOW INDUSTRIES'},
    {value: 'AUTOZONE', label: 'AUTOZONE'},
    {value: 'DIGIKEY', label: 'DIGIKEY'},
    {value: 'COLLEGE POINT ELEC SUPPLIES', label: 'COLLEGE POINT ELEC SUPPLIES'},
    {value: 'ZORO', label: 'ZORO'},
    {value: 'AIRWELD', label: 'AIRWELD'},
    {value: 'The Peelle Company Ltd.', label: 'The Peelle Company Ltd.'},
    {value: 'LOWE', label: 'LOWE'},
    {value: 'Savaria USA Inc', label: 'Savaria USA Inc'},
    {value: 'GRAINGER', label: 'GRAINGER'},
    {value: 'Certified elevator Escalator Products', label: 'Certified elevator Escalator Products'},
    {value: 'QB11 ELECTRICAL SUPPLY INC.', label: 'QB11 ELECTRICAL SUPPLY INC.'},
    {value: 'Royal Metal Products USA Corp.', label: 'Royal Metal Products USA Corp.'},
];

const installationGroupOptions = [
    {value: "关哥", label: "关哥"},
    {value: "强哥", label: "强哥"},
    {value: "宝哥", label: "宝哥"},
    {value: "新哥", label: "新哥"},
    {value: "罗师傅", label: "罗师傅"},
    {value: "阿斌", label: "阿斌"},
    {value: "杰哥", label: "杰哥"},
    {value: "Mark", label: "Mark"},
    {value: "小强", label: "小强"},
];

const wiringGroupOptions = [
    {value: "Allen", label: "Allen"},
    {value: "海哥", label: "海哥"},
    {value: "Alex", label: "Alex"},
    {value: "李工", label: "李工"}
];

const containerTypes = [
    {key: 'container-on-going', label: <FormattedMessage id="projectPage.ongoing"/>},
    {key: 'container-in-progress', label: <FormattedMessage id="projectPage.inProgress"/>},
    {key: 'container-temporary', label: <FormattedMessage id="projectPage.temporary"/>},
    {key: 'container-pending-accept', label: <FormattedMessage id="projectPage.pendingAccept"/>},
    {key: 'container-completed', label: <FormattedMessage id="projectPage.completed"/>}
]

const materialTypes = {
    'All': <FormattedMessage id="materialTable.all"/>,
    'Elevator Parts': <FormattedMessage id="materialTable.elevatorParts"/>,
    'Materials': <FormattedMessage id="materialTable.materials"/>,
    'Consumables': <FormattedMessage id="materialTable.consumables"/>,
    'Tools': <FormattedMessage id="materialTable.tools"/>,
    'Others': <FormattedMessage id="materialTable.others"/>,
};

const fileTypes = {
    'all': <FormattedMessage id="main.allFiles"/>,
    'contracts': <FormattedMessage id="main.contracts"/>,
    'drawings': <FormattedMessage id="main.drawings"/>,
    'materials': <FormattedMessage id="main.materials"/>,
    'doors': <FormattedMessage id="main.elevatorDoors"/>,
    'elevators': <FormattedMessage id="main.elevators"/>,
    'construction': <FormattedMessage id="main.constructionStatus"/>,
    'others': <FormattedMessage id="main.otherFiles"/>,
};

function preprocessPercentages(percentageObject) {
    const processedPercentages = {};
    for (const key in percentageObject) {
        processedPercentages[key] = percentageObject[key] === null ? 0 : percentageObject[key];
    }
    return processedPercentages;
}

function isPercentageValid(percentageObject) {
    const totalPercentage = Object.values(percentageObject).reduce((sum, current) => sum + current, 0);
    return totalPercentage === 100;
}

function calculateCurrentPercentage(percentages, currentStep) {
    const stepOrder = [
        'contractSigned',
        'drawingApproved',
        'materialOrdered',
        'materialArrived',
        'runPlatform',
        'doorDelivered',
        'elevatorFinished',
        'acceptance'
    ];

    // Sum up the percentages up to and including the current step
    let currentPercentage = 0;
    for (let i = 0; i <= currentStep; i++) {
        const key = stepOrder[i];
        currentPercentage += percentages[key] || 0;  // Use '0' as a fallback if the key doesn't exist
    }

    return currentPercentage;
}

function isWithinDOBRange(dobStartDate, dobEndDate) {
    if (!dobStartDate) return false;

    const today = new Date();
    const start = new Date(dobStartDate);
    let end = dobEndDate ? new Date(dobEndDate) : null;

    if (start && end) {
        return start <= today && today <= end;
    } else {
        return start <= today;
    }
}


const Items = (
    {
        containerId,
        id,
        name,
        jobSite,
        coordinates,
        installationGroup,
        wiringGroup,
        deliveryGroup,
        elevatorData,
        code,
        signDate,
        createToYear,
        createDate,
        startDate,
        drawingApprovalDate,
        workPermitDate,
        materialOrderDate,
        materialArriveDate,
        runPlatformDate,
        doorDeliveredDate,
        finishElevatorDate,
        acceptDate,
        completeDate,
        dobStartDate,
        dobEndDate,
        dobDetails,
        memoCount,
        percentage,
        onDelete,
        isDeletingProject,
        onEdit,
        onMove,
        isEditingProject,
        isRenderingProjects,
        os,
        userId
    }
) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: id,
        data: {
            type: 'item',
        },
    });

    const style = {
        transition,
        transform: CSS.Translate.toString(transform),
        margin: '10px',
        opacity: isDragging ? 0.5 : 1,  // Apply a lower opacity when the item is being dragged
    };

    const formatDate = (date) => {
        if (!date) return null;

        return new Date(date).toISOString().split('T')[0];
    };

    const projectCurrentStep = () => {
        if (acceptDate) return 7;
        if (finishElevatorDate) return 6;
        if (doorDeliveredDate) return 5;
        if (runPlatformDate) return 4;
        if (materialArriveDate) return 3;
        if (materialOrderDate) return 2;
        if (drawingApprovalDate) return 1;
        if (signDate) return 0;

        return -1;
    };


    const intl = useIntl(); // Hook to get the intl object
    const materialTableRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const mapToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const [editProjectForm] = Form.useForm();
    const [editDOBForm] = Form.useForm();
    const [csrfToken, setCsrfToken] = useState(null);
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});

    const [inventoryData, setInventoryData] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [selectMaterialType, setSelectMaterialType] = useState('Elevator Parts');
    const [orderData, setOrderData] = useState([]);
    const [orderQuantities, setOrderQuantities] = useState([]);
    const [inputFieldsVisible, setInputFieldsVisible] = useState({});
    const [orderNumber, setOrderNumber] = useState(null);
    const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
    const [isDeletingOrder, setIsDeletingOrder] = useState(false);

    const [searchVendorText, setSearchVendorText] = useState('');
    const [filteredVendorOptions, setFilteredVendorOptions] = useState(vendorOptions);

    const [viewHover, setViewHover] = useState(false);
    const [editHover, setEditHover] = useState(false);
    const [orderHover, setOrderHover] = useState(false);
    const [deleteHover, setDeleteHover] = useState(false);

    const [showProjectDetailModal, setShowProjectDetailModal] = useState(false);
    const [showProjectEditModal, setShowProjectEditModal] = useState(false);
    const [showProjectDeleteModal, setShowProjectDeleteModal] = useState(false);

    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
    const [showOrderDetailModal, setShowOrderDetailModal] = useState(false);
    const [showOrderTableDetailModal, setShowOrderTableDetailModal] = useState(false);  // Modal for one single order
    const [showOrderEditModal, setShowOrderEditModal] = useState(false);
    const [showOrderTableEditModal, setShowOrderTableEditModal] = useState(false);  // Modal for one single order
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [editingOrderRow, setEditingOrderRow] = useState(null);  // Update singe item in one single order
    const [isEditingOrderRow, setIsEditingOrderRow] = useState(false);
    const [originalOrderData, setOriginalOrderData] = useState({});  // Store the original order data, if cancel
    const [showMaterialOrderModal, setShowMaterialOrderModal] = useState(false);
    const [showEditSuspendModal, setShowEditSuspendModal] = useState(false);
    const [isDeleteMaterialDisabled, setIsDeleteMaterialDisabled] = useState(false);

    const [showUploadFileModal, setShowUploadFileModal] = useState(false);

    const [showAddMemoModal, setShowAddMemoModal] = useState(false);
    const [projectMemos, setProjectMemos] = useState([]);
    const [initialMemoCount, setInitialMemoCount] = useState(memoCount);
    const [isFetchingMemos, setIsFetchingMemos] = useState(false);
    const [isAddingMemo, setIsAddingMemo] = useState(false);
    const [isDeletingMemo, setIsDeletingMemo] = useState(false);
    const [isEditingMemo, setIsEditingMemo] = useState(false);

    const [viewFileType, setViewFileType] = useState('all');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isRenderingFiles, setIsRenderingFiles] = useState(false);
    const [isDeletingFile, setIsDeletingFile] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(true);
    const [showAddMemoButton, setShowAddMemoButton] = useState(true);

    const [showElevatorEditModal, setShowElevatorEditModal] = useState(false);
    const [editingElevatorRow, SetEditingElevatorRow] = useState(null);
    const [projectElevatorData, setProjectElevatorData] = useState(elevatorData || []);
    const [originalElevatorData, setOriginalElevatorData] = useState({});
    const [elevatorNumber, setElevatorNumber] = useState(projectElevatorData.length);
    const [pendingElevatorData, setPendingElevatorData] = useState([]);  // Record intermedia data, if cancel
    const [showElevatorDetailModal, setShowElevatorDetailModal] = useState(false);

    const [confirmDeletion, setConfirmDeletion] = useState(false);

    const [projectCoordinates, setProjectCoordinates] = useState(coordinates);
    const [latestWorkPermitDate, setLatestWorkPermitDate] = useState(workPermitDate ? dayjs(workPermitDate) : null);
    const [workPermitApproval, setWorkPermitApproval] = useState(!!workPermitDate);
    const [latestDOBDetails, setLatestDOBDetails] = useState(dobDetails);
    const [currentStep, setCurrentStep] = useState(projectCurrentStep);
    const [datesOutsideForm, setDatesOutsideForm] = useState(
        {
            "contractSigned": signDate ? new Date(signDate) : null,
            "drawingApproved": drawingApprovalDate ? new Date(drawingApprovalDate) : null,
            "materialOrdered": materialOrderDate ? new Date(materialOrderDate) : null,
            "materialArrived": materialArriveDate ? new Date(materialArriveDate) : null,
            "runPlatform": runPlatformDate ? new Date(runPlatformDate) : null,
            "doorDelivered": doorDeliveredDate ? new Date(doorDeliveredDate) : null,
            "elevatorFinished": finishElevatorDate ? new Date(finishElevatorDate) : null,
            "acceptance": acceptDate ? new Date(acceptDate) : null,
            "dobStarted": dobStartDate ? new Date(dobStartDate) : null,
            "dobEnded": dobEndDate ? new Date(dobEndDate) : null,
        }
    );

    const [editingPercentage, setEditingPercentage] = useState(false);
    const [projectPercentage, setProjectPercentage] = useState(percentage || {
        contractSigned: 0,
        drawingApproved: 0,
        materialOrdered: 0,
        materialArrived: 0,
        runPlatform: 0,
        doorDelivered: 0,
        elevatorFinished: 0,
        acceptance: 0
    });
    const [currentPercentage, setCurrentPercentage] = useState(calculateCurrentPercentage(projectPercentage, currentStep));
    const [isSuspending, setIsSuspending] = useState(isWithinDOBRange(dobStartDate, dobEndDate));

    const [tableHeight, setTableHeight] = useState(500);
    const [exportAllOrders, setExportAllOrders] = useState(false);

    const [dragDisabled, setDragDisabled] = useState(true);
    const [modalPosition, setModalPosition] = useState({x: 0, y: 0});
    const draggleRef = useRef(null);


    useEffect(() => {
        setElevatorNumber(projectElevatorData.length);
    }, [projectElevatorData]);

    const handleMoveClick = (e) => {
        setShowProjectEditModal(false);
        onMove(id, name, containerId, e.key);
    };

    const containerTypesMenuProps = {
        items: containerTypes.filter(container => container.key !== containerId),
        onClick: handleMoveClick,
    };

    const updateTableHeight = () => {
        const parentHeight = materialTableRef.current?.parentElement?.offsetHeight || window.innerHeight;
        const newTableHeight = parentHeight * 0.57;
        setTableHeight(newTableHeight);
    };

    useEffect(() => {
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);

        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);

    useEffect(() => {
        if (exportAllOrders) {
            handleOrderExport('view');
            setExportAllOrders(false); // Reset the flag after exporting
        }
    }, [selectedOrderDetails, exportAllOrders]);

    const handleSubtitleChange = (id, value) => {
        setProjectPercentage(prev => ({...prev, [id]: value}));
    };

    const getMemberName = (value, options) => {
        const option = options.find(option => option.value === value);
        return option ? option.label : value;
    };

    const projectDetails = [
        {label: <FormattedMessage id="projectPage.jobSite"/>, children: jobSite},
        {
            label: <FormattedMessage id="projectPage.installationGroup"/>,
            children: (installationGroup ? installationGroup.map(group => getMemberName(group, installationGroupOptions)).join(", ") : "")
        },
        {
            label: <FormattedMessage id="projectPage.wiringGroup"/>,
            children: (wiringGroup ? wiringGroup.map(group => getMemberName(group, wiringGroupOptions)).join(", ") : "")
        },
        {
            label: <FormattedMessage id="projectPage.deliveryGroup"/>,
            children: deliveryGroup ? deliveryGroup.join(", ") : ""
        },
        {
            label: <FormattedMessage id="projectPage.elevatorNumber"/>,
            children:
                <Space>
                    {elevatorNumber}
                    <Link onClick={() => {
                        setShowElevatorDetailModal(true);
                    }}>
                        <FileSearchOutlined/>
                    </Link>
                </Space>
        },
        {
            label: <FormattedMessage id="projectPage.orderStatus"/>,
            children:
                <Link onClick={() => {
                    setShowOrderDetailModal(true);
                    fetchOrderData();
                    setSelectMaterialType('All');
                }}>
                    <FileSearchOutlined/>
                </Link>
        },
        {label: <FormattedMessage id="projectPage.code"/>, children: code}
    ];

    const projectTimeline = [
        {label: formatDate(createDate), children: <FormattedMessage id="projectPage.projectCreated"/>},
        {label: formatDate(signDate), children: <FormattedMessage id="projectPage.contractSigned"/>},
        {label: formatDate(startDate), children: <FormattedMessage id="projectPage.projectStarted"/>},
        {label: formatDate(drawingApprovalDate), children: <FormattedMessage id="projectPage.drawingApproved"/>},
        {label: formatDate(workPermitDate), children: <FormattedMessage id="projectPage.workPermitApproved"/>},
        {label: formatDate(materialOrderDate), children: <FormattedMessage id="projectPage.materialOrdered"/>},
        {label: formatDate(materialArriveDate), children: <FormattedMessage id="projectPage.materialArrived"/>},
        {label: formatDate(runPlatformDate), children: <FormattedMessage id="projectPage.runPlatform"/>},
        {label: formatDate(doorDeliveredDate), children: <FormattedMessage id="projectPage.doorDelivered"/>},
        {label: formatDate(finishElevatorDate), children: <FormattedMessage id="projectPage.elevatorFinished"/>},
        {label: formatDate(acceptDate), children: <FormattedMessage id="projectPage.acceptance"/>},
        {color: 'red', label: formatDate(dobStartDate), children: <FormattedMessage id="projectPage.DOBSuspendedStart"/>},
        {label: formatDate(dobEndDate), children: <FormattedMessage id="projectPage.DOBSuspendedEnd"/>},
        {color: 'green', label: formatDate(completeDate), children: <FormattedMessage id="projectPage.projectCompleted"/>},
    ];

    const projectTimelineSorted = projectTimeline
        .filter(item => item.label !== null) // Remove items with null dates
        .sort((a, b) => new Date(a.label) - new Date(b.label)); // Sort items by date

    const projectStep = [
        {
            id: 'projectCreated',
            title: (
                <Space>
                    <FormattedMessage id="projectPage.projectCreated"/>
                    {editingPercentage ? (
                        <Link onClick={(event) => {
                            setProjectPercentage(percentage);
                            event.stopPropagation();
                            setEditingPercentage(false);
                        }}>
                            <FormattedMessage id="main.cancel"/>
                        </Link>
                    ) : (
                        <Link onClick={(event) => {
                            event.stopPropagation();
                            setEditingPercentage(true);
                        }}>
                            <FormattedMessage id="main.edit"/> <PercentageOutlined/>
                        </Link>
                    )}
                </Space>
            ),
        },
        {id: 'contractSigned', title: <FormattedMessage id="projectPage.contractSigned"/>},
        {id: 'drawingApproved', title: <FormattedMessage id="projectPage.drawingApproved"/>},
        {id: 'materialOrdered', title: <FormattedMessage id="projectPage.materialOrdered"/>},
        {id: 'materialArrived', title: <FormattedMessage id="projectPage.materialArrived"/>},
        {id: 'runPlatform', title: <FormattedMessage id="projectPage.runPlatform"/>},
        {id: 'doorDelivered', title: <FormattedMessage id="projectPage.doorDelivered"/>},
        {id: 'elevatorFinished', title: <FormattedMessage id="projectPage.elevatorFinished"/>},
        {id: 'acceptance', title: <FormattedMessage id="projectPage.acceptance"/>},
    ].map((step, index) => ({
        ...step,
        subTitle: (index !== 0 && editingPercentage) ? (
            <Space>
                <InputNumber min={0}
                             max={100}
                             value={projectPercentage[step.id] ?? 0}
                             onChange={value => handleSubtitleChange(step.id, value)}
                             style={{width: '60px'}}
                             onClick={(event) => event.stopPropagation()}
                />%
            </Space>
        ) : (step.id !== 'projectCreated' ? `${projectPercentage[step.id]}%` : null),
        disabled: index > currentStep + 2 || (editingPercentage && index > currentStep),
        datePicker: index !== 0 ? (
            <DatePicker style={{width: '100%'}}
                        disabled={index > currentStep + 1}
                        value={datesOutsideForm[step.id] ? dayjs(datesOutsideForm[step.id]) : null}
                        onChange={(date) => handleDateChange(step.id, date)}
                        onClick={(event) => event.stopPropagation()}
            />
        ) : null
    }));

    const searchMethod = os === 'MacOS' ? (
        <>
            <img src={commandIcon} alt="Command" style={{width: '20px', verticalAlign: 'middle'}}/>
            +<img src={fIcon} alt="F key" style={{width: '20px', verticalAlign: 'middle'}}/>
        </>
    ) : (
        <>
            <img src={ctrlIcon} alt="Ctrl" style={{width: '20px', verticalAlign: 'middle'}}/>
            +<img src={fIcon} alt="F key" style={{width: '20px', verticalAlign: 'middle'}}/>
        </>
    );

    // Table columns for elevator details
    const elevatorDetailColumns = [
        {
            title: <FormattedMessage id="projectPage.elevatorCode"/>,
            dataIndex: 'elevatorCode',
            render: (text, record) => editingElevatorRow === record.key ? (
                <Input value={text}
                       placeholder={intl.formatMessage({id: "projectPage.elevatorCode"})}
                       onChange={(e) => handleEditElevatorFieldChange(record.key, 'elevatorCode', e.target.value)}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="projectPage.speed"/>,
            dataIndex: 'speed',
            render: (text, record) => editingElevatorRow === record.key ? (
                <InputNumber min={0}
                             value={record.speed}
                             placeholder={intl.formatMessage({id: "projectPage.speed"})}
                             onChange={(value) => handleEditElevatorFieldChange(record.key, 'speed', value)}
                             style={{width: '100%'}}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="projectPage.capacity"/>,
            dataIndex: 'capacity',
            render: (text, record) => editingElevatorRow === record.key ? (
                <InputNumber min={0}
                             value={record.capacity}
                             placeholder={intl.formatMessage({id: "projectPage.capacity"})}
                             onChange={(value) => handleEditElevatorFieldChange(record.key, 'capacity', value)}
                             style={{width: '100%'}}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="projectPage.machineType"/>,
            dataIndex: 'machineType',
            render: (text, record) => editingElevatorRow === record.key ? (
                <Input value={text}
                       placeholder={intl.formatMessage({id: "projectPage.machineType"})}
                       onChange={(e) => handleEditElevatorFieldChange(record.key, 'machineType', e.target.value)}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="projectPage.landing"/>,
            dataIndex: 'landing',
            render: (text, record) => editingElevatorRow === record.key ? (
                <InputNumber min={0}
                             value={record.landing}
                             placeholder={intl.formatMessage({id: "projectPage.landing"})}
                             onChange={(value) => handleEditElevatorFieldChange(record.key, 'landing', value)}
                             style={{width: '100%'}}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="projectPage.doorOpening"/>,
            dataIndex: 'doorOpening',
            render: (text, record) => editingElevatorRow === record.key ? (
                <InputNumber min={0}
                             value={record.doorOpening}
                             placeholder={intl.formatMessage({id: "projectPage.doorOpening"})}
                             onChange={(value) => handleEditElevatorFieldChange(record.key, 'doorOpening', value)}
                             style={{width: '100%'}}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="projectPage.doorType"/>,
            dataIndex: 'doorType',
            render: (text, record) => editingElevatorRow === record.key ? (
                <Input value={text}
                       placeholder={intl.formatMessage({id: "projectPage.doorType"})}
                       onChange={(e) => handleEditElevatorFieldChange(record.key, 'doorType', e.target.value)}
                />
            ) : (
                text
            )
        },
        {
            title: <FormattedMessage id="main.actions"/>,
            dataIndex: 'action',
            key: 'action',
            fix: 'right',
            render: (_, record) => (
                editingElevatorRow === record.key ? (
                    <>
                        <Link onClick={() => saveElevatorDetails(record.key)}>
                            <FormattedMessage id="main.save"/>
                        </Link>
                        <span> | </span>
                        <Link onClick={() => cancelElevatorEdit(record.key)}>
                            <FormattedMessage id="main.cancel"/>
                        </Link>
                    </>
                ) : (
                    <>
                        <Link onClick={() => editElevatorRow(record.key)}>
                            <FormattedMessage id="main.edit"/>
                        </Link>
                        <span> | </span>
                        <Popconfirm title={intl.formatMessage({id: "warning.confirmDelete"})}
                                    onConfirm={() => handleElevatorDelete(record.key)}
                        >
                            <Link>
                                <FormattedMessage id="main.delete"/>
                            </Link>
                        </Popconfirm>
                    </>
                )
            )
        }
    ];

    // Table columns for all orders
    const orderDetailColumns = [
        {title: <FormattedMessage id="materialTable.orderNumber"/>, dataIndex: 'orderNumber'},
        {
            title: <FormattedMessage id="materialTable.orderDate"/>,
            dataIndex: 'orderDate',
            width: 220,
            key: 'orderDate',
            render: (text) => dayjs.utc(text).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')
        },
        {title: <FormattedMessage id="materialTable.creator"/>, dataIndex: 'creatorName'},
        {
            title: <FormattedMessage id="materialTable.status"/>,
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                record.complete ? (
                    <Tag icon={<CheckCircleOutlined/>} color="success">
                        <FormattedMessage id="main.completed"/>
                    </Tag>
                ) : (
                    <Tag icon={<SyncOutlined spin/>} color="processing">
                        <FormattedMessage id="main.inProgress"/>
                    </Tag>
                )
            )
        },
        {
            title: <FormattedMessage id="main.actions"/>,
            dataIndex: 'editAction',
            key: 'editAction',
            render: (_, record) => (
                <>
                    <Link onClick={() => {
                        handleSetOrderTableDetail(record.orderNumber);
                        handleViewOrderTableDetail('edit');
                    }}>
                        <FormattedMessage id="main.edit"/>
                    </Link>
                    <span> | </span>
                    <Popconfirm title={intl.formatMessage({id: "warning.confirmDelete"})}
                                onConfirm={handleOrderDelete}
                                okButtonProps={{loading: isDeletingOrder}}
                    >
                        <Link disabled={hasProgressGreaterOrEqualOne(record.orderNumber)}
                              onClick={() => setSelectedOrderNumber(record.orderNumber)}
                        >
                            <FormattedMessage id="main.delete"/>
                        </Link>
                    </Popconfirm>
                </>
            )
        },
        {
            title: <FormattedMessage id="main.actions"/>,
            dataIndex: 'viewAction',
            key: 'viewAction',
            render: (_, record) => (
                <>
                    <Link onClick={() => {
                        handleSetOrderTableDetail(record.orderNumber);
                        handleViewOrderTableDetail('view');
                    }}>
                        <FormattedMessage id="main.view"/>
                    </Link>
                    <span> | </span>
                    <Link onClick={() => {
                        handleSetOrderTableDetail(record.orderNumber);
                        setExportAllOrders(true);
                    }}>
                        <FormattedMessage id="main.export"/>
                    </Link>
                </>
            )
        },
    ];

    // Table columns for one single order
    const orderDetailTableColumns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'itemId', key: 'itemId', width: 80, fixed: 'left'},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 240, fixed: 'left'},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={0}
                                 value={record.costPrice}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'costPrice', value)}
                                 style={{width: '100%'}}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={0}
                                 value={record.retailPrice}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'retailPrice', value)}
                                 style={{width: '100%'}}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="materialTable.quantity"/>,
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={1}
                                 value={record.orderQuantity}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'orderQuantity', value)}
                                 style={{width: '100%'}}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.totalPrice"/>,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: 120,
            render: (text, record) => (record.orderQuantity * record.retailPrice).toFixed(2),
        },
        {
            title: <FormattedMessage id="materialTable.progress"/>,
            dataIndex: 'progress',
            key: 'progress',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={0}
                                 max={record.orderQuantity}
                                 value={record.progress}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'progress', value)}
                                 style={{width: '100%'}}
                    />
                ) : (
                    text
                )
            )
        },
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 60},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 150,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <AutoComplete options={filteredVendorOptions}
                                  value={record.vendor}
                                  onSearch={handleVendorSearch}
                                  style={{width: '100%'}}
                                  placeholder={intl.formatMessage({id: "inventoryPage.selectVendor"})}
                                  onChange={(value) => handleEditOrderFieldChange(record.itemId, 'vendor', value)}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 150,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <TextArea value={record.memo}
                              onChange={(e) => handleEditOrderFieldChange(record.itemId, 'memo', e.target.value)}
                              placeholder={intl.formatMessage({id: "inventoryPage.enterMemo"})}
                              autoSize
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.arrivalDate"/>,
            dataIndex: 'arrivalDate',
            key: 'arrivalDate',
            width: 150,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <DatePicker value={record.arrivalDate ? dayjs(record.arrivalDate) : null}
                                onChange={(date) => handleEditOrderFieldChange(record.itemId, 'arrivalDate', date ? date.startOf('day').format('YYYY-MM-DD') : null)}
                    />
                ) : (
                    record.arrivalDate ? dayjs(record.arrivalDate).format('YYYY-MM-DD') : ''
                )
            )
        }
    ];

    // Table columns for complete inventory
    const columns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'productCode', key: 'productCode', width: 80, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 250, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 100,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const costPrice = item.costPrice ?? null;
                return inputFieldsVisible[record.key] ? (
                    <InputNumber value={costPrice}
                                 min={0}
                                 onChange={(value) => handleCostPriceChange(record.key, value)}
                                 style={{width: '100%'}}
                    />
                ) : costPrice;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 100,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const retailPrice = item.retailPrice ?? null;
                return inputFieldsVisible[record.key] ? (
                    <InputNumber value={retailPrice}
                                 min={0}
                                 onChange={(value) => handleSubmitOrderFieldChange(record.key, 'retailPrice', value)}
                                 style={{width: '100%'}}
                    />
                ) : retailPrice;
            },
        },
        {title: <FormattedMessage id="inventoryPage.inStockQuantity"/>, dataIndex: 'inStockQuantity', key: 'inStockQuantity', width: 80},
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 80},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 180,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                return inputFieldsVisible[record.key] ? (
                    <AutoComplete options={filteredVendorOptions}
                                  value={item.vendor}
                                  onChange={(value) => handleSubmitOrderFieldChange(record.key, 'vendor', value)}
                                  onSearch={handleVendorSearch}
                                  style={{width: '100%'}}
                                  placeholder={intl.formatMessage({id: "inventoryPage.selectVendor"})}
                    />
                ) : item.vendor;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 180,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                return inputFieldsVisible[record.key] ? (
                    <TextArea value={item.memo}
                              onChange={(e) => handleSubmitOrderFieldChange(record.key, 'memo', e.target.value)}
                              placeholder={intl.formatMessage({id: "inventoryPage.enterMemo"})}
                              autoSize
                    />
                ) : item.memo;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.quantity"/>,
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            width: 140,
            fixed: 'right',
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const quantity = item.orderQuantity ?? null;

                return (
                    <>
                        {inputFieldsVisible[record.key] && (
                            <Space>
                                <InputNumber min={0}
                                             value={quantity}
                                             onChange={(value) => handleSubmitOrderFieldChange(record.key, 'orderQuantity', value)}
                                             style={{width: '100%'}}
                                />
                                <Button icon={<DeleteOutlined/>}
                                        size="small"
                                        onClick={(value) => handleQuantityDelete(record.key, value)}
                                />
                            </Space>
                        )}
                        {!inputFieldsVisible[record.key] && (
                            <Button icon={<PlusOutlined/>}
                                    size="small"
                                    onClick={() => toggleNumberInput(record.key, true)}
                            />
                        )}
                    </>
                );
            },
        }
    ];

    const viewOrderColumns = orderDetailColumns.map((item, index) => ({
        ...item,
        hidden: index === 4,
    }));

    const editOrderColumns = orderDetailColumns.map((item, index) => ({
        ...item,
        hidden: index === 5,
    }));

    const viewElevatorDetailColumns = elevatorDetailColumns.map((item, index) => ({
        ...item,
        hidden: index === 7,
    }));

    const onSelectRowKeysChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        // Check if any of the selected rows have progress greater than 1
        const hasProgressGreaterThanOne = newSelectedRowKeys.some(key => {
            const selectedRow = selectedOrderDetails.find(item => item.itemId === key);
            return selectedRow && selectedRow.progress > 1;
        });

        // Check if all rows are selected
        const allRowsSelected = selectedOrderDetails.length === newSelectedRowKeys.length;

        // Disable delete button if any row has progress greater than 1 or all rows are selected
        setIsDeleteMaterialDisabled(hasProgressGreaterThanOne || allRowsSelected);
    };

    const orderRowSelection = {
        selectedRowKeys,
        columnWidth: 50,
        onChange: onSelectRowKeysChange,
        getCheckboxProps: (record) => ({
            disabled: isEditingOrderRow,
        }),
    };

    // Create orders
    const toggleNumberInput = (itemId, show) => {
        setInputFieldsVisible(prev => ({...prev, [itemId]: show}));
        if (!orderQuantities.find(item => item.itemId === itemId)) {
            const item = inventoryData.find(item => item.key === itemId);
            if (item) {
                setOrderQuantities(prev => [
                    ...prev,
                    {
                        itemId,
                        key: itemId,
                        orderQuantity: 1,
                        costPrice: 0,
                        retailPrice: 0,
                        progress: 0,
                        productName: item.productName,
                        specification: item.specification,
                        unit: item.unit,
                    }
                ]);
            }
        }
    };

    const handleSubmitOrderFieldChange = (itemId, field, value) => {
        setOrderQuantities(prev => prev.map(item =>
            item.itemId === itemId ? {...item, [field]: value} : item
        ));
    };

    const handleCostPriceChange = (itemId, newCostPrice) => {
        setOrderQuantities(prev => prev.map(item =>
            item.itemId === itemId ? {...item, costPrice: newCostPrice ?? 0, retailPrice: newCostPrice ?? 0} : item
        ));
    };

    const handleQuantityDelete = (itemId, newQuantity) => {
        setInputFieldsVisible(prev => ({...prev, [itemId]: false}));
        setOrderQuantities(prev => prev.filter(item => item.itemId !== itemId));
    };

    const handleVendorSearch = (value) => {
        setSearchVendorText(value);
        const filteredOptions = vendorOptions.filter(option =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredVendorOptions(filteredOptions);
    };

    // Update orders
    const handleEditOrderFieldChange = (itemId, field, value) => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) => {
                if (item.itemId === itemId) {
                    const updatedItem = {...item, [field]: value};
                    if (field === 'orderQuantity' || field === 'retailPrice') {
                        updatedItem.totalPrice = (updatedItem.orderQuantity || 0) * (updatedItem.retailPrice || 0);
                    }
                    return updatedItem;
                }
                return item;
            })
        );
    };

    const deleteSelected = () => {
        setSelectedOrderDetails(prevDetails =>
            prevDetails.filter(item => !selectedRowKeys.includes(item.itemId))
        );
        setSelectedRowKeys([]); // Clear the selection
    };

    const arriveAllSelected = () => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) =>
                selectedRowKeys.includes(item.itemId)
                    ? {...item, progress: item.orderQuantity}
                    : item
            )
        );
    };

    const saveNewOrderInfo = () => {
        setEditingOrderRow(null);
    };

    const cancelEditOrder = (itemId) => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) =>
                item.itemId === itemId ? {...item, ...originalOrderData[itemId]} : item
            )
        );
        setEditingOrderRow(null);
    };

    const editOrderRow = (itemId) => {
        const item = selectedOrderDetails.find((item) => item.itemId === itemId);

        setOriginalOrderData((prevData) => ({
            ...prevData,
            [itemId]: {
                progress: item.progress,
                costPrice: item.costPrice,
                retailPrice: item.retailPrice,
                orderQuantity: item.orderQuantity,
                vendor: item.vendor,
                memo: item.memo,
                arrivalDate: item.arrivalDate,
                deletable: item.deletable
            }
        }));
        setEditingOrderRow(itemId);
    };

    const hasProgressGreaterOrEqualOne = (orderNumber) => {
        const order = orderData.find(order => order.orderNumber === orderNumber);
        return order && order.orderDetails.some(item => item.progress >= 1);
    };

    // View materials/orders
    const handleChangeType = (value) => {
        setSelectMaterialType(value);

        if (materialTableRef.current) {
            materialTableRef.current.scrollTo({
                index: 0
            });
        }
    };

    const handleSetOrderTableDetail = (orderNumber) => {
        const order = orderData.find(order => order.orderNumber === orderNumber);
        if (order) {
            setSelectedOrderDetails(order.orderDetails);
            setSelectedOrderNumber(orderNumber);
        }
    };

    const handleViewOrderTableDetail = (tableType) => {
        if (tableType === "edit") {
            setShowOrderTableEditModal(true);
        } else {
            setShowOrderTableDetailModal(true);
        }
    };

    const getTotalSelectedPrice = () => {
        const total = selectedRowKeys.reduce((total, key) => {
            const item = selectedOrderDetails.find((detail) => detail.itemId === key);
            return total + (item.orderQuantity * item.retailPrice);
        }, 0);

        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total);
    };

    const getTotalPrice = () => {
        const total = selectedOrderDetails.reduce((total, item) => {
            return total + (item.orderQuantity * item.retailPrice);
        }, 0);

        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total);
    };

    // Elevator related
    const handleAddElevator = () => {
        const newElevator = {
            key: uuidv4().split('-').pop(),
            elevatorCode: null,
            speed: null,
            capacity: null,
            machineType: null,
            landing: null,
            doorOpening: null,
            doorType: null,
        };
        setProjectElevatorData([...projectElevatorData, newElevator]);
    };

    const editElevatorRow = (itemId) => {
        const item = projectElevatorData.find((item) => item.key === itemId);

        setOriginalElevatorData((prevData) => ({
            ...prevData,
            [itemId]: {
                elevatorCode: item.elevatorCode,
                speed: item.speed,
                capacity: item.capacity,
                machineType: item.machineType,
                landing: item.landing,
                doorOpening: item.doorOpening,
                doorType: item.doorType,
            }
        }));
        SetEditingElevatorRow(itemId);
    };

    const saveElevatorDetails = () => {
        SetEditingElevatorRow(null);
    };

    const cancelElevatorEdit = (itemId) => {
        setProjectElevatorData((prevData) =>
            prevData.map((item) =>
                item.key === itemId ? {...item, ...originalElevatorData[itemId]} : item
            )
        );
        SetEditingElevatorRow(null);
    };


    const handleElevatorDelete = (key) => {
        setProjectElevatorData((prevData) =>
            prevData.filter((item) => item.key !== key)
        );
    };

    const handleEditElevatorFieldChange = (itemId, field, value) => {
        setProjectElevatorData((prevData) =>
            prevData.map((item) =>
                item.key === itemId ? {...item, [field]: value} : item
            )
        );
    };

    const removeEmptyElevatorRows = () => {
        setProjectElevatorData((prevData) =>
            prevData.filter((item) =>
                item.elevatorNumber !== null ||
                item.speed !== null ||
                item.capacity !== null ||
                item.machineType !== null ||
                item.landing !== null ||
                item.doorOpening !== null ||
                item.doorType !== null
            )
        );
    };

    const fetchInventoryData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_inventory/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_inventory',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsFetchingData(false);
            if (response.data.status === "success") {
                const inventoryData = response.data.responseData;
                setInventoryData(inventoryData);
            } else {
                errorMessage("Failed to fetch projects: " + response.data.responseData.errorMessage);
                console.error("Failed to fetch projects: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsFetchingData(false);
            errorMessage("Error fetching projects: " + error);
            console.error("Error fetching projects: ", error);
        });
    };

    const fetchOrderData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_order/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsFetchingData(false);
            if (response.data.status === "success") {
                const orderInfo = response.data.responseData;
                setOrderData(orderInfo);
            } else {
                errorMessage("Failed to fetch projects: " + response.data.responseData.errorMessage);
                console.error("Failed to fetch projects: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsFetchingData(false);
            errorMessage("Error fetching projects: " + error);
            console.error("Error fetching projects: ", error);
        });
    };

    const fetchProjectFiles = () => {
        setIsRenderingFiles(true);
        axios.post(
            "/api/fetch_project_files/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_project_files',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsRenderingFiles(false);
            if (response.data.status === "success") {
                const files = response.data.responseData.files.map(file => ({
                    uid: file.key,
                    name: file.key.split('/').pop(),
                    status: 'done',
                    url: file.url
                }));
                setUploadedFiles(files);
            } else {
                errorMessage("Failed to fetch project files: " + response.data.responseData.errorMessage);
                console.error("Failed to fetch project files: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsRenderingFiles(false);
            errorMessage("Error fetching project files: " + error);
            console.error("Error fetching project files: ", error);
        });
    };

    const fetchMemos = () => {
        setIsFetchingMemos(true);
        axios.post(
            "/api/fetch_memos/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_memos',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsFetchingMemos(false);
            if (response.data.status === "success") {
                const memoInfo = response.data.responseData;
                setProjectMemos(memoInfo);
            } else {
                errorMessage("Failed to fetch memos: " + response.data.responseData.errorMessage);
                console.error("Failed to fetch memos: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsFetchingMemos(false);
            errorMessage("Error fetching memos: " + error);
            console.error("Error fetching memos: ", error);
        });
    };

    const handleAddMemo = (newMemo) => {
        setIsAddingMemo(true);

        const memoWithId = {
            ...newMemo,
            memoId: uuidv4()
        };

        axios.post(
            "/api/add_memo/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    authKey: userInfo.authKey,
                    memoInfo: memoWithId,
                    datetime: new Date()
                },
                requestName: 'add_memo',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsAddingMemo(false);
            if (response.data.status === "success") {
                setProjectMemos(prevMemos => [memoWithId, ...prevMemos]);
                setInitialMemoCount(preCount => preCount + 1);
                successMessage(<FormattedMessage id="projectPage.createMemo.success"/>);
            } else {
                errorMessage("Failed to add memo: " + response.data.responseData.errorMessage);
                console.error("Failed to add memo: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsAddingMemo(false);
            errorMessage("Error adding memo: " + error);
            console.error("Error adding memo: ", error);
        });
    };

    const handleEditMemo = (memoId, editedMemo) => {
        setIsEditingMemo(true);

        axios.post(
            "/api/edit_memo/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    memoId: memoId,
                    newMemoInfo: editedMemo,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'edit_memo',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsEditingMemo(false);
            if (response.data.status === "success") {
                setProjectMemos(prevMemos =>
                    prevMemos.map(memo =>
                        memo.memoId === memoId
                            ? {...memo, ...editedMemo, memoDate: editedMemo.memoDate}
                            : memo
                    )
                );
                successMessage(<FormattedMessage id="projectPage.updateMemo.success"/>);
            } else {
                errorMessage("Failed to edit memo: " + response.data.responseData.errorMessage);
                console.error("Failed to edit memo: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsEditingMemo(false);
            errorMessage("Error editing memo: " + error);
            console.error("Error editing memo: ", error);
        });
    };

    const handleDeleteMemo = (memoId) => {
        setIsDeletingMemo(true);

        axios.post(
            "/api/delete_memo/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    memoId: memoId,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'delete_memo',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsDeletingMemo(false);
            if (response.data.status === "success") {
                setProjectMemos(prevMemos => prevMemos.filter(memo => memo.memoId !== memoId));
                setInitialMemoCount(prevCount => prevCount - 1);
                successMessage(<FormattedMessage id="projectPage.deleteMemo.success"/>);
            } else {
                errorMessage("Failed to delete memo: " + response.data.responseData.errorMessage);
                console.error("Failed to delete memo: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsDeletingMemo(false);
            errorMessage("Error deleting memo: " + error);
            console.error("Error deleting memo: ", error);
        });
    };

    const uploadProjectFiles = (filesToUpload, fileType) => {
        const formData = new FormData();
        formData.append('parameters', JSON.stringify({
            userId: userId,
            authKey: userInfo.authKey,
            os: os,
            projectId: id,
            fileType: fileType,
            datetime: new Date(),
            requestName: 'upload_project_files',
            jobId: uuidv4()
        }));

        // Append each file to the formData object
        filesToUpload.forEach(file => {
            formData.append('files', file.file); // Use the actual file object
        });

        axios.post(
            "/api/upload_project_files/",
            formData,
            {
                headers: {'X-CSRFToken': csrfToken, 'Content-Type': 'multipart/form-data'},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                // setUploadedFiles([...uploadedFiles, ...filesToUpload]);
            } else {
                errorMessage("Failed to upload project files: " + response.data.responseData.errorMessage);
                console.error("Failed to upload project files: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            errorMessage("Error uploading project files: " + error);
            console.error("Error uploading project files: ", error);
        });
    };

    const downloadProjectFile = (file) => {
        axios.post(
            "/api/download_project_file/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    fileName: file.name,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'download_project_file',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                window.open(file.url, '_blank');
            } else {
                errorMessage("Failed to download project files: " + response.data.responseData.errorMessage);
                console.error("Failed to download project files: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsDeletingFile(false);
            errorMessage("Error downloading project files: " + error);
            console.error("Error downloading project files:", error);
        });
    };

    const deleteProjectFiles = (file) => {
        setIsDeletingFile(true);
        axios.post(
            "/api/delete_project_file/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    fileName: file.name,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'delete_project_file',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsDeletingFile(false);
            if (response.data.status === "success") {
                setUploadedFiles((prevFiles) => prevFiles.filter((item) => item.uid !== file.uid));
            } else {
                errorMessage("Failed to delete project files: " + response.data.responseData.errorMessage);
                console.error("Failed to delete project files: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsDeletingFile(false);
            errorMessage("Error deleting project files: " + error);
            console.error("Error deleting project files: ", error);
        });
    };

    const handleDateChange = (stepId, date) => {
        setDatesOutsideForm(prevDates => ({
            ...prevDates,
            [stepId]: date ? new Date(date) : null
        }));
    };

    const handleStepChange = (current) => {
        setCurrentStep(current);
        if (current === -1) return;

        const stepId = projectStep[current + 1].id;
        if (!datesOutsideForm[stepId]) {
            setDatesOutsideForm(prevDates => ({
                ...prevDates,
                [stepId]: dayjs(new Date())
            }));
        }
    };

    const handleEditDOBSuspend = (dobForm) => {
        handleDateChange("dobStarted", dobForm['suspend-start-date']);
        handleDateChange("dobEnded", dobForm['suspend-end-date']);
        setLatestDOBDetails(dobForm['suspend-details']);

        setShowEditSuspendModal(false);
    };

    const handleOrderSubmit = () => {
        const filteredOrderQuantities = orderQuantities.filter(item => item.orderQuantity > 0);
        if (filteredOrderQuantities.length === 0) {
            warningMessage(<FormattedMessage id="projectPage.submitOrder.empty"/>);
            return;
        }

        setIsSubmittingOrder(true);
        axios.post(
            "/api/submit_order/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    datetime: new Date(),
                    orderNumber: orderNumber,
                    authKey: userInfo.authKey,
                    orderDetails: filteredOrderQuantities
                },
                requestName: 'submit_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsSubmittingOrder(false);
            if (response.data.status === "success") {
                setShowMaterialOrderModal(false);
                setTimeout(() => {
                    setOrderQuantities([]);
                    setInputFieldsVisible({});
                    handleChangeType('Elevator Parts');
                    successMessage(intl.formatMessage({id: "projectPage.submitOrder.success"}, {orderNumber: orderNumber}));
                }, 300);
            } else {
                errorMessage("Failed to submit order: " + response.data.responseData.errorMessage);
                console.error("Failed to submit order: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsSubmittingOrder(false);
            errorMessage("Error submitting order: " + error);
            console.error("Error submitting order: ", error);
        });
    };

    const handleOrderUpdate = () => {
        setIsUpdatingOrder(true);

        axios.post(
            "/api/update_order/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    projectId: id,
                    orderNumber: selectedOrderNumber,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                    orderDetails: selectedOrderDetails
                },
                requestName: 'update_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsUpdatingOrder(false);
            if (response.data.status === "success") {
                const orderCompleted = response.data.responseData.orderCompleted;
                setOrderData(prevOrderData =>
                    prevOrderData.map(order =>
                        order.orderNumber === selectedOrderNumber ? {...order, complete: orderCompleted, orderDetails: selectedOrderDetails} : order
                    )
                );
                setShowOrderTableEditModal(false);
                setTimeout(() => {
                    setEditingOrderRow(null);  // Disable number input
                    setSelectedRowKeys([]);
                    setIsEditingOrderRow(false);
                    successMessage(intl.formatMessage({id: "projectPage.updateOrder.success"}, {orderNumber: selectedOrderNumber}));
                }, 300);
            } else {
                errorMessage("Failed to update order: " + response.data.responseData.errorMessage);
                console.error("Failed to update order: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error updating order: " + error);
            console.error("Error updating order: ", error);
        });
    };

    const handleOrderDelete = () => {
        setIsDeletingOrder(true);

        axios.post(
            "/api/delete_order/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    orderNumber: selectedOrderNumber,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                },
                requestName: 'delete_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsDeletingOrder(false);
            if (response.data.status === "success") {
                setOrderData(prevOrderData => prevOrderData.filter(order => order.orderNumber !== selectedOrderNumber));
                successMessage(intl.formatMessage({id: "projectPage.deleteOrder.success"}, {orderNumber: selectedOrderNumber}));
            } else {
                errorMessage("Failed to delete order: " + response.data.responseData.errorMessage);
                console.error("Failed to delete order: ", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error deleting order: " + error);
            console.error("Error deleting order: ", error);
        });
    };

    const handleOrderExport = (tableType) => {
        let selectedData;

        if (selectedRowKeys.length > 0) {
            selectedData = selectedOrderDetails
                .filter(item => selectedRowKeys.includes(item.key))
                .map(item => ({
                    ...item,
                    totalPrice: (item.orderQuantity || 0) * (item.retailPrice || 0)
                }));
        } else {
            selectedData = selectedOrderDetails.map(item => ({
                ...item,
                totalPrice: (item.orderQuantity || 0) * (item.retailPrice || 0)
            }));
        }

        axios.post(
            "/api/export_order/",
            {
                parameters: {
                    userId: userId,
                    os: os,
                    orderNumber: selectedOrderNumber,
                    orderDetail: selectedData,
                    tableType: tableType,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                },
                requestName: 'export_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                responseType: 'blob',
                withCredentials: true
            }
        ).then(response => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `order_${selectedOrderNumber}.xlsx`);
                document.body.appendChild(link);
                link.click();
            } else {
                errorMessage("Failed to export order.");
                console.error("Failed to export order.");
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error exporting order: " + error);
            console.error("Error exporting order: ", error);
        });
    };

    const handleEditSubmit = (formData) => {
        const processedPercentages = preprocessPercentages(projectPercentage);

        const updatedProjectData = {
            containerId: containerId,
            id: id,
            name: formData['project-name'],
            jobSite: formData['job-site'],
            coordinates: projectCoordinates,
            installationGroup: formData['installation-group'] || [],
            wiringGroup: formData['wiring-group'] || [],
            deliveryGroup: formData['delivery-group'] || [],
            elevatorData: projectElevatorData || [],
            code: formData['code-compliant'],
            signDate: datesOutsideForm['contractSigned'] || null,
            createToYear: createToYear,
            createDate: new Date(createDate),
            startDate: formData['project-start-date'] ? new Date(formData['project-start-date']) : null,
            drawingApprovalDate: datesOutsideForm['drawingApproved'],
            workPermitDate: latestWorkPermitDate,
            materialOrderDate: datesOutsideForm['materialOrdered'],
            materialArriveDate: datesOutsideForm['materialArrived'],
            runPlatformDate: datesOutsideForm['runPlatform'],
            doorDeliveredDate: datesOutsideForm['doorDelivered'],
            finishElevatorDate: datesOutsideForm['elevatorFinished'],
            acceptDate: datesOutsideForm['acceptance'],
            completeDate: formData['project-complete-date'] ? new Date(formData['project-complete-date']) : null,
            dobStartDate: datesOutsideForm['dobStarted'],
            dobEndDate: datesOutsideForm['dobEnded'],
            dobDetails: latestDOBDetails,
            memoCount: initialMemoCount,
            percentage: processedPercentages
        };

        if (isPercentageValid(processedPercentages)) {
            setProjectPercentage(processedPercentages);
            onEdit(updatedProjectData);
            setEditingPercentage(false);
            setIsSuspending(isWithinDOBRange(datesOutsideForm['dobStarted'], datesOutsideForm['dobEnded']));
        } else {
            warningMessage(<FormattedMessage id="projectPage.invalidPercentage"/>);
        }
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <div ref={setNodeRef} style={style}>
            {contextHolder}
            <Badge.Ribbon text={isSuspending ? <FormattedMessage id="projectPage.DOBSuspendedStart"/> : `${currentPercentage}%`} color={isSuspending ? "red" : "default"}>
                <Card className="project-card"
                      size="small"
                      bordered={false}
                      style={{width: 250}}
                      actions={[
                          <div onMouseEnter={() => setViewHover(true)}
                               onMouseLeave={() => setViewHover(false)}
                               onClick={!isRenderingProjects ? () => setShowProjectDetailModal(true) : undefined}
                          >
                              <EyeOutlined key="view" style={{color: viewHover ? '' : 'grey'}}/>
                          </div>,
                          <div onMouseEnter={() => setEditHover(true)}
                               onMouseLeave={() => setEditHover(false)}
                               onClick={!isRenderingProjects ? () => {
                                   setShowProjectEditModal(true);
                                   setShowAddMemoButton(true);
                                   setCurrentStep(projectCurrentStep);
                                   setCurrentPercentage(calculateCurrentPercentage(projectPercentage, currentStep));
                                   setWorkPermitApproval(!!workPermitDate);
                                   setLatestWorkPermitDate(workPermitDate ? dayjs(workPermitDate) : null);
                               } : undefined}
                          >
                              <EditOutlined key="edit" style={{color: editHover ? 'green' : 'grey'}}/>
                          </div>,
                          <div onMouseEnter={() => setOrderHover(true)}
                               onMouseLeave={() => setOrderHover(false)}
                               onClick={!isRenderingProjects ? () => {
                                   setOrderNumber(uuidv4().split('-').pop());
                                   setShowMaterialOrderModal(true);
                                   fetchInventoryData();
                               } : undefined}
                          >
                              <ShoppingOutlined key="order" style={{color: orderHover ? 'orange' : 'grey'}}/>
                          </div>,
                          <div onMouseEnter={() => setDeleteHover(true)}
                               onMouseLeave={() => setDeleteHover(false)}
                               onClick={!isRenderingProjects ? () => setShowProjectDeleteModal(true) : undefined}
                          >
                              <DeleteOutlined key="delete" style={{color: deleteHover ? 'red' : 'grey'}}/>
                          </div>,
                      ]}
                >
                    {/* Draggable area */}
                    <Skeleton loading={isRenderingProjects} avatar active paragraph={{rows: 1}}>
                        <div {...listeners} {...attributes} style={{cursor: 'grab'}}>
                            <Meta avatar={null}
                                  title={name}
                                  description={jobSite.split(',')[1] ?? jobSite}
                            />
                        </div>
                    </Skeleton>
                </Card>
            </Badge.Ribbon>
            <Modal title={name}
                   centered
                   width="830px"
                   open={showProjectDetailModal}
                   onCancel={() => setShowProjectDetailModal(false)}
                   footer={
                       <div style={{display: 'flex', justifyContent: 'space-between'}}>
                           <div>
                               <Button type="dashed"
                                       icon={<FolderViewOutlined/>}
                                       onClick={() => {
                                           fetchProjectFiles();
                                           setShowUploadFileModal(true);
                                           setShowUploadButton(false); // Hide upload button when opened from view files
                                       }}
                                       style={{marginRight: '8px'}}
                               >
                                   <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.viewFiles"/></span>
                               </Button>
                               <Badge count={initialMemoCount} size="small" color="blue">
                                   <Button type="dashed"
                                           icon={<FundViewOutlined/>}
                                           onClick={() => {
                                               fetchMemos();
                                               setShowAddMemoModal(true);
                                               setShowAddMemoButton(false);
                                           }}
                                   >
                                        <span style={{paddingLeft: '0px'}}>
                                            <FormattedMessage id="main.viewMemos"/>
                                        </span>
                                   </Button>
                               </Badge>
                           </div>
                           <div style={{color: 'lightgrey'}}>
                               {id}
                           </div>
                       </div>
                   }
            >
                <Row style={{height: "480px"}}>
                    <Col span={12}>
                        <Descriptions column={1} items={projectDetails}/>
                    </Col>
                    <Col span={12}>
                        <Timeline mode={"left"} items={projectTimelineSorted.filter(item => item.label !== null)}/>
                    </Col>
                </Row>
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.orderStatusTable"}, {name: name})}
                   centered
                   width="60%"
                   open={showOrderDetailModal}
                   onCancel={() => {
                       setShowOrderDetailModal(false)
                   }}
                   footer={null}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={orderData}
                           columns={viewOrderColumns}
                           loading={isFetchingData}
                           pagination={false}
                           scroll={{y: '55vh'}}
                           size="middle"
                    />
                </div>
            </Modal>
            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => setDragDisabled(false)}
                     onMouseOut={() => setDragDisabled(true)}
                >
                    {intl.formatMessage({id: "materialTable.viewOrderNumber"}, {orderNumber: selectedOrderNumber})}
                </div>
            }
                   centered
                   width="60%"
                   open={showOrderTableDetailModal}
                   onCancel={() => {
                       setShowOrderTableDetailModal(false);
                       setSelectedRowKeys([]);
                       setModalPosition({x: 0, y: 0});
                   }}
                   maskClosable={false}
                   footer={null}
                   modalRender={(modal) => (
                       <Draggable disabled={dragDisabled}
                                  bounds={draggleRef.current ? draggleRef.current : undefined}
                                  position={modalPosition}
                                  onStop={(e, data) => {
                                      setModalPosition({x: data.x, y: data.y});
                                  }}
                       >
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={selectedOrderDetails}
                           columns={orderDetailTableColumns}
                           loading={isFetchingData}
                           pagination={false}
                           virtual={true}
                           scroll={{y: '55vh'}}
                           size="middle"
                           footer={() => (
                               <div style={{textAlign: 'left', paddingRight: '20px'}}>
                                   <FormattedMessage id="inventoryPage.totalPrice"/>: {getTotalPrice()}
                               </div>
                           )}
                    />
                    <br/>
                    {selectedRowKeys.length > 0 && (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%', padding: '10px'}}>
                            <Button.Group>
                                <Button icon={<ExportOutlined/>}
                                        style={{width: '120px'}}
                                        onClick={() => handleOrderExport('view')}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.export"/></span>
                                </Button>
                            </Button.Group>
                        </div>
                    )}
                </div>
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.elevatorTable"}, {name: name})}
                   centered
                   width="65%"
                   open={showElevatorDetailModal}
                   onCancel={() => {
                       setShowElevatorDetailModal(false)
                   }}
                   footer={null}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={projectElevatorData}
                           columns={viewElevatorDetailColumns}
                           pagination={false}
                           scroll={{y: '55vh'}}
                           size="middle"
                    />
                </div>
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.editing"}, {name: name})}
                   centered
                   width="1060px"
                   open={showProjectEditModal}
                   onCancel={() => {  // Also need to update cancel button below
                       editProjectForm.resetFields();
                       editDOBForm.resetFields();
                       setCurrentStep(projectCurrentStep);
                       setCurrentPercentage(calculateCurrentPercentage(projectPercentage, currentStep));
                       setShowProjectEditModal(false);
                       setProjectElevatorData(elevatorData);
                       setSelectMaterialType('Elevator Parts');
                       setEditingPercentage(false);
                       setProjectPercentage(percentage);
                       setProjectCoordinates(coordinates);
                   }}
                   footer={null}
            >
                <Row gutter={50} style={{height: "630px"}}>
                    <Col span={12}>
                        <Form name="edit-project-form"
                              form={editProjectForm}
                              onFinish={handleEditSubmit}
                              initialValues={{
                                  "project-name": name,
                                  "job-site": jobSite,
                                  "installation-group": installationGroup,
                                  "wiring-group": wiringGroup,
                                  "delivery-group": deliveryGroup,
                                  "code-compliant": code,
                                  "project-start-date": startDate ? dayjs(startDate) : null,
                                  "project-complete-date": completeDate ? dayjs(completeDate) : null,
                              }}
                        >
                            <Form.Item name="project-name"
                                       label={<FormattedMessage id="projectPage.projectName"/>}
                                       rules={[{required: true, message: <FormattedMessage id="projectPage.projectName.req"/>}]}
                            >
                                <Input placeholder={intl.formatMessage({id: "projectPage.projectName.des"})}/>
                            </Form.Item>
                            <Form.Item name="job-site"
                                       label={<FormattedMessage id="projectPage.jobSite"/>}
                                       rules={[{required: true, message: <FormattedMessage id="projectPage.jobSite.req"/>}]}
                            >
                                <AddressAutocomplete mapToken={mapToken}
                                                     initialValue={jobSite}
                                                     onSelectAddress={(selected) => {
                                                         editProjectForm.setFieldsValue({'job-site': selected.value});
                                                         setProjectCoordinates(selected.coordinates);
                                                     }}
                                />
                            </Form.Item>
                            <Form.Item name="installation-group"
                                       label={<FormattedMessage id="projectPage.installationGroup"/>}
                            >
                                <Select mode="tags"
                                        style={{width: '100%'}}
                                        placeholder={intl.formatMessage({id: "projectPage.installationGroup.des"})}
                                        options={installationGroupOptions}
                                        tokenSeparators={[',', '，']}
                                />
                            </Form.Item>
                            <Form.Item name="wiring-group"
                                       label={<FormattedMessage id="projectPage.wiringGroup"/>}
                            >
                                <Select mode="tags"
                                        style={{width: '100%'}}
                                        placeholder={intl.formatMessage({id: "projectPage.wiringGroup.des"})}
                                        options={wiringGroupOptions}
                                        tokenSeparators={[',', '，']}
                                />
                            </Form.Item>
                            <Form.Item name="delivery-group"
                                       label={<FormattedMessage id="projectPage.deliveryGroup"/>}
                            >
                                <Select mode="tags"
                                        style={{width: '100%'}}
                                        placeholder={intl.formatMessage({id: "projectPage.deliveryGroup.des"})}
                                        suffixIcon={null}
                                        tokenSeparators={[',', '，']}
                                        open={false}
                                />
                            </Form.Item>
                            <Form.Item name="elevator-number"
                                       label={<FormattedMessage id="projectPage.elevatorNumber"/>}
                            >
                                <Space>
                                    {elevatorNumber}
                                    <Button type="dashed"
                                            icon={<ToolOutlined/>}
                                            onClick={() => {
                                                setPendingElevatorData([...projectElevatorData]);
                                                setShowElevatorEditModal(true);
                                            }}
                                    >
                                        <span style={{paddingLeft: '0px'}}><FormattedMessage id="projectPage.editElevatorDetail"/></span>
                                    </Button>
                                </Space>
                            </Form.Item>
                            <Form.Item name="order-status"
                                       label={<FormattedMessage id="projectPage.orderStatus"/>}
                            >
                                <Button type="dashed"
                                        icon={<ToolOutlined/>}
                                        onClick={() => {
                                            setShowOrderEditModal(true);
                                            fetchOrderData();
                                        }}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="projectPage.editOrderStatus"/></span>
                                </Button>
                            </Form.Item>
                            <Form.Item name="code-compliant" label={<FormattedMessage id="projectPage.code"/>}>
                                <Select placeholder={intl.formatMessage({id: "projectPage.code.des"})}>
                                    <Option value="ASME A17.1-2000, NYC 2014">ASME A17.1-2000, NYC 2014</Option>
                                    <Option value="ASME A17.1-2013, NYC 2022">ASME A17.1-2013, NYC 2022</Option>
                                    <Option value="ASME A17.1-2022">ASME A17.1-2022</Option>
                                </Select>
                            </Form.Item>
                            {(containerId !== "container-on-going") && (
                                <>
                                    <Form.Item name="project-start-date"
                                               label={<FormattedMessage id="projectPage.startDate"/>}
                                               rules={[{required: true, message: <FormattedMessage id="projectPage.startDate.req"/>}]}
                                    >
                                        <DatePicker/>
                                    </Form.Item>
                                    <Form.Item label={<FormattedMessage id="projectPage.workPermitDate"/>}>
                                        <DatePicker name="work-permit-date"
                                                    disabled={!workPermitApproval}
                                                    value={latestWorkPermitDate}
                                                    onChange={(date) => setLatestWorkPermitDate(date)}
                                        />
                                        <span style={{marginLeft: 20,}}>
                                            <Checkbox checked={workPermitApproval}
                                                      onChange={(e) => {
                                                          setWorkPermitApproval(e.target.checked);
                                                          if (!e.target.checked) {
                                                              setLatestWorkPermitDate(null);
                                                          }
                                                      }}
                                            >
                                                <FormattedMessage id="projectPage.approved"/>
                                            </Checkbox>
                                        </span>
                                    </Form.Item>
                                    {containerId === "container-completed" && (
                                        <>
                                            <Form.Item name="project-complete-date"
                                                       label={<FormattedMessage id="projectPage.completeDate"/>}
                                                       rules={[{required: true, message: <FormattedMessage id="projectPage.completeDate.req"/>}]}
                                            >
                                                <DatePicker/>
                                            </Form.Item>
                                        </>
                                    )}
                                </>
                            )}
                        </Form>
                    </Col>
                    <Col span={11}>
                        <div style={{paddingLeft: '30px'}}>
                            <Steps initial={-1}
                                   current={currentStep}
                                   status="finish"
                                   onChange={handleStepChange}
                                   direction="vertical"
                                   items={projectStep.map(step => ({
                                       title: (
                                           <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '5px'}}>
                                               <div>{step.title} {step.subTitle}</div>
                                               <br/><br/>
                                               <div>{step.datePicker}</div>
                                           </div>
                                       ),
                                       disabled: step.disabled
                                   }))}
                            />
                        </div>
                    </Col>
                </Row>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Space>
                        {containerId !== "container-on-going" && (
                            <Button danger
                                    onClick={() => {
                                        editDOBForm.setFieldsValue({
                                            "suspend-start-date": datesOutsideForm['dobStarted'] ? dayjs(datesOutsideForm['dobStarted']) : null,
                                            "suspend-end-date": datesOutsideForm['dobEnded'] ? dayjs(datesOutsideForm['dobEnded']) : null,
                                            "suspend-details": latestDOBDetails
                                        });
                                        setShowEditSuspendModal(true);
                                    }}
                            >
                                <FormattedMessage id="projectPage.DOBSuspended"/>
                            </Button>
                        )}
                        <Button type="dashed"
                                icon={<UploadOutlined/>}
                                onClick={() => {
                                    fetchProjectFiles();
                                    setShowUploadFileModal(true);
                                    setShowUploadButton(true);
                                }}
                        >
                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.uploadFiles"/></span>
                        </Button>
                        <Badge count={initialMemoCount} size="small" color="blue">
                            <Button type="dashed"
                                    icon={<FormOutlined/>}
                                    onClick={() => {
                                        fetchMemos();
                                        setShowAddMemoModal(true);
                                        setShowAddMemoButton(true);
                                    }}
                            >
                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="projectPage.addMemo"/></span>
                            </Button>
                        </Badge>
                    </Space>
                    <div>
                        <Space>
                            <Dropdown menu={containerTypesMenuProps}
                                      placement="bottomRight"
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <FormattedMessage id="projectPage.moveTo"/> <DownOutlined/>
                                </a>
                            </Dropdown>
                            <Button key="cancel"
                                    onClick={() => {  // Also need to update onCancel above
                                        editProjectForm.resetFields();
                                        editDOBForm.resetFields();
                                        setCurrentStep(projectCurrentStep);
                                        setCurrentPercentage(calculateCurrentPercentage(projectPercentage, currentStep));
                                        setShowProjectEditModal(false);
                                        setProjectElevatorData(elevatorData);
                                        setSelectMaterialType('Elevator Parts');
                                        setEditingPercentage(false);
                                        setProjectPercentage(percentage);
                                        setProjectCoordinates(coordinates);
                                    }}
                            >
                                <FormattedMessage id="main.cancel"/>
                            </Button>
                            <Button key="submit"
                                    type="primary"
                                    loading={isEditingProject}
                                    onClick={() => {
                                        editProjectForm.submit();
                                    }}>
                                <FormattedMessage id="main.save"/>
                            </Button>
                        </Space>
                    </div>
                </div>
            </Modal>
            <Modal title={showUploadButton ? intl.formatMessage({id: "projectPage.uploadFiles"}, {name: name}) : intl.formatMessage({id: "projectPage.viewFiles"}, {name: name})}
                   centered
                   width="1110px"
                   open={showUploadFileModal}
                   onCancel={() => {
                       setShowUploadFileModal(false);
                       setTimeout(() => {
                           setShowUploadButton(true);
                       }, 300);
                   }}
                   footer={null}
                   zIndex={1030} // Set a high zIndex to ensure the modal appears on top
                   destroyOnClose
            >
                <FileManager userInfo={userInfo}
                             id={id}
                             viewFileType={viewFileType}
                             showUploadButton={showUploadButton}
                             isRenderingFiles={isRenderingFiles}
                             isDeletingFile={isDeletingFile}
                             fileList={uploadedFiles}
                             onUploadFiles={uploadProjectFiles}
                             onDownloadFile={downloadProjectFile}
                             onDeleteFile={deleteProjectFiles}
                />
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.memoList"}, {projectName: name})}
                   centered
                   width="550px"
                   open={showAddMemoModal}
                   onCancel={() => {
                       setShowAddMemoModal(false);
                   }}
                   footer={null}
                   zIndex={1030}
            >
                <ProjectMemoList memos={projectMemos}
                                 isFetchingMemos={isFetchingMemos}
                                 isAddingMemo={isAddingMemo}
                                 isDeletingMemo={isDeletingMemo}
                                 isEditingMemo={isEditingMemo}
                                 onAddMemo={handleAddMemo}
                                 onEditMemo={handleEditMemo}
                                 onDeleteMemo={handleDeleteMemo}
                                 showAddMemoButton={showAddMemoButton}
                />
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.elevatorTable"}, {name: name})}
                   centered
                   width="80%"
                   open={showElevatorEditModal}
                   onCancel={() => {
                       setProjectElevatorData([...pendingElevatorData]);  // Revert to the original data
                       setShowElevatorEditModal(false);
                       setTimeout(() => {
                           SetEditingElevatorRow(null);
                       }, 300);
                   }}
                   onOk={() => {
                       removeEmptyElevatorRows();
                       setShowElevatorEditModal(false);
                       setTimeout(() => {
                           SetEditingElevatorRow(null);
                       }, 300);
                   }}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={projectElevatorData}
                           columns={elevatorDetailColumns}
                           pagination={false}
                           scroll={{y: '55vh'}}
                           size="middle"
                    />
                    <Button type="dashed"
                            onClick={handleAddElevator}
                            icon={<PlusOutlined/>}
                            style={{width: '100%', marginTop: 16}}
                    >
                        <span style={{paddingLeft: '0px'}}><FormattedMessage id="projectPage.addElevator"/></span>
                    </Button>
                </div>
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.orderStatusTable"}, {name: name})}
                   centered
                   width="60%"
                   open={showOrderEditModal}
                   onCancel={() => setShowOrderEditModal(false)}
                   footer={null}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={orderData}
                           columns={editOrderColumns}
                           loading={isFetchingData}
                           pagination={false}
                           scroll={{y: '55vh'}}
                           size="middle"
                    />
                </div>
            </Modal>
            <Modal title={
                <div style={{width: '100%', cursor: 'move'}}
                     onMouseOver={() => setDragDisabled(false)}
                     onMouseOut={() => setDragDisabled(true)}
                >
                    {intl.formatMessage({id: "materialTable.editOrderNumber"}, {orderNumber: selectedOrderNumber})}
                </div>
            }
                   centered
                   width="60%"
                   open={showOrderTableEditModal}
                   onCancel={() => {
                       setShowOrderTableEditModal(false);
                       setTimeout(() => {
                           setModalPosition({x: 0, y: 0});
                           setEditingOrderRow(null);  // Disable number input
                           setSelectedRowKeys([]);
                           setIsEditingOrderRow(false);
                       }, 300);
                   }}
                   maskClosable={false}
                   okText={intl.formatMessage({id: "main.save"})}
                   onOk={handleOrderUpdate}
                   okButtonProps={{loading: isUpdatingOrder}}
                   modalRender={(modal) => (
                       <Draggable disabled={dragDisabled}
                                  bounds={draggleRef.current ? draggleRef.current : undefined}
                                  position={modalPosition}
                                  onStop={(e, data) => {
                                      setModalPosition({x: data.x, y: data.y});
                                  }}
                       >
                           <div ref={draggleRef}>{modal}</div>
                       </Draggable>
                   )}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Table dataSource={selectedOrderDetails}
                           columns={orderDetailTableColumns}
                           rowSelection={orderRowSelection}
                           loading={isFetchingData}
                           pagination={false}
                           virtual={true}
                           scroll={{y: '60vh'}}
                           size="middle"
                           footer={() => (
                               <div style={{textAlign: 'left', paddingRight: '20px'}}>
                                   <FormattedMessage id="inventoryPage.totalPrice"/>: {getTotalSelectedPrice()}
                               </div>
                           )}
                    />
                    <br/>
                    {selectedRowKeys.length > 0 && (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%', padding: '10px'}}>
                            <Button.Group>
                                {isEditingOrderRow ? (
                                    <>
                                        <Button icon={<SaveOutlined/>}
                                                style={{width: '120px'}}
                                                onClick={() => {
                                                    saveNewOrderInfo();
                                                    setIsEditingOrderRow(false);
                                                    setSelectedRowKeys([]);
                                                }}
                                        >
                                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.save"/></span>
                                        </Button>
                                        <Button icon={<CloseOutlined/>}
                                                style={{width: '120px'}}
                                                onClick={() => {
                                                    cancelEditOrder(selectedRowKeys[0]);
                                                    setIsEditingOrderRow(false);
                                                }}
                                        >
                                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.cancel"/></span>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button icon={<EditOutlined/>}
                                                style={{width: '120px'}}
                                                disabled={selectedRowKeys.length > 1}
                                                onClick={() => {
                                                    editOrderRow(selectedRowKeys[0]);
                                                    setIsEditingOrderRow(true);
                                                }}
                                        >
                                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.edit"/></span>
                                        </Button>
                                        <Button icon={<CheckOutlined/>}
                                                style={{width: '120px'}}
                                                onClick={arriveAllSelected}
                                        >
                                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="materialTable.complete"/></span>
                                        </Button>
                                        <Button icon={<ExportOutlined/>}
                                                style={{width: '120px'}}
                                                onClick={() => handleOrderExport('edit')}
                                        >
                                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.export"/></span>
                                        </Button>
                                        <Button icon={<DeleteOutlined/>}
                                                style={{width: '120px'}}
                                                disabled={isDeleteMaterialDisabled}
                                                onClick={deleteSelected}
                                        >
                                            <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.delete"/></span>
                                        </Button>
                                    </>
                                )}
                            </Button.Group>
                        </div>
                    )}
                </div>
            </Modal>
            <Modal title={intl.formatMessage({id: "materialTable.orderMaterial"}, {name: name})}
                   centered
                   width="80%"
                   open={showMaterialOrderModal}
                   footer={null}
                   onCancel={() => {
                       setShowMaterialOrderModal(false);
                       setTimeout(() => {
                           setOrderQuantities([]);
                           setInputFieldsVisible({});
                           handleChangeType('Elevator Parts');
                       }, 300);
                   }}
                   maskClosable={false}
            >
                <div style={{maxHeight: '70vh', height: '70vh'}}>
                    <Space direction="vertical" style={{width: '100%'}} gap="large">
                        <Flex wrap="wrap" gap="middle" style={{width: '100%', justifyContent: 'space-between'}}>
                            <Flex wrap="wrap" gap="middle">
                                {Object.entries(materialTypes).slice(1).map(([key, value]) => (
                                    <Button key={key}
                                            type={selectMaterialType === key ? 'primary' : 'text'}
                                            onClick={() => handleChangeType(key)}
                                            size='middle'
                                    >
                                        {value}
                                    </Button>
                                ))}
                            </Flex>
                            <Tag style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {intl.formatMessage({id: "inventoryPage.search"}, {method: searchMethod})}
                            </Tag>
                        </Flex>
                        <Table dataSource={selectMaterialType === 'All' ? inventoryData : inventoryData.filter(item => item.type === selectMaterialType)}
                               ref={materialTableRef}
                               columns={columns}
                               loading={isFetchingData}
                               virtual={true}
                               pagination={false}
                               scroll={{x: 1300, y: tableHeight}}
                               size="middle"
                        />
                    </Space>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Space>
                        {intl.formatMessage({id: "materialTable.orderNumber.detail"}, {orderNumber: orderNumber})}
                    </Space>
                    <div>
                        <Space>
                            <Button key="cancel"
                                    onClick={() => {
                                        setShowMaterialOrderModal(false);
                                        setTimeout(() => {
                                            setOrderQuantities([]);
                                            setInputFieldsVisible({});
                                            handleChangeType('Elevator Parts');
                                        }, 300);
                                    }}
                            >
                                <FormattedMessage id="main.cancel"/>
                            </Button>
                            <Button key="submit"
                                    type="primary"
                                    loading={isSubmittingOrder}
                                    onClick={() => {
                                        handleOrderSubmit();
                                    }}
                            >
                                <FormattedMessage id="main.submit"/>
                            </Button>
                        </Space>
                    </div>
                </div>
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.addDOBSuspended"})}
                   centered
                   open={showEditSuspendModal}
                   onCancel={() => {
                       setShowEditSuspendModal(false);
                       setTimeout(() => {
                           editDOBForm.resetFields();
                       }, 300);
                   }}
                   onOk={() => editDOBForm.submit()}
            >
                <Form name="dob-suspend-form"
                      form={editDOBForm}
                      onFinish={handleEditDOBSuspend}
                      initialValues={{
                          "suspend-start-date": dobStartDate ? dayjs(dobStartDate) : null,
                          "suspend-end-date": dobEndDate ? dayjs(dobEndDate) : null,
                          "suspend-details": dobDetails
                      }}
                >
                    <Form.Item name="suspend-start-date"
                               label={<FormattedMessage id="projectPage.suspendStartDate"/>}
                    >
                        <DatePicker/>
                    </Form.Item>
                    <Form.Item name="suspend-end-date"
                               label={<FormattedMessage id="projectPage.suspendEndDate"/>}
                    >
                        <DatePicker/>
                    </Form.Item>
                    <Form.Item name="suspend-details"
                               label={<FormattedMessage id="projectPage.suspendDetails"/>}
                    >
                        <TextArea rows={4} style={{resize: 'none'}}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title={intl.formatMessage({id: "projectPage.deleting"}, {name: name})}
                   centered
                   open={showProjectDeleteModal}
                   onOk={() => {
                       onDelete(id, name);
                   }}
                   okText={<FormattedMessage id="main.delete"/>}
                   okType="danger"
                   okButtonProps={{type: "primary", loading: isDeletingProject, disabled: !confirmDeletion,}}
                   cancelText={<FormattedMessage id="main.cancel"/>}
                   onCancel={() => {
                       setShowProjectDeleteModal(false);
                       setConfirmDeletion(false);
                   }}
            >
                <Space direction="vertical">
                    <Text type="danger" strong><FormattedMessage id="projectPage.deleteMessage"/></Text>
                    <Checkbox key="delete-checkbox"
                              checked={confirmDeletion}
                              onChange={(e) => setConfirmDeletion(e.target.checked)}>
                        <FormattedMessage id="projectPage.deleteConformation" values={{name: name}}/>
                    </Checkbox>
                </Space>
            </Modal>
        </div>
    );
};

export default Items;
