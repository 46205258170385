import React, {useState} from 'react';
import {Button, Divider, Form, Input, Layout, Menu, message, Space, Switch, Typography} from 'antd';
import {AccountBookOutlined, FundProjectionScreenOutlined, LogoutOutlined, PieChartOutlined, SettingOutlined, ShopOutlined,} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from "react-intl";
import dayjs from 'dayjs';
import {useCookies} from "react-cookie";
import DropDownMenuLanguage from "./components/DropDownMenuLanguage";
import axios from "axios";
import {v4 as uuidv4} from "uuid";


dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Text, Title, Link} = Typography;
const {TextArea} = Input;


function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem(<FormattedMessage id="sider.dashboard"/>, 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.projects"/>, 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.inventory"/>, 'inventory', <ShopOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.accounting"/>, 'accounting', <AccountBookOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.settings"/>, 'settings', <SettingOutlined/>),
];


const SettingsPage = ({setLocale}) => {
    const navigate = useNavigate();
    const intl = useIntl(); // Hook to get the intl object
    const [messageApi, contextHolder] = message.useMessage();
    const [personalInfoForm] = Form.useForm();
    const [generalForm] = Form.useForm();
    const [resetPasswordForm] = Form.useForm();

    const [csrfToken, setCsrfToken] = useState(null);
    const [cookies, setCookie] = useCookies(['userInfo']);

    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});

    const [isUpdatingPersonalInfo, setIsUpdatingPersonalInfo] = useState(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
    const [isSwitchingShowLog, setIsSwitchingShowLog] = useState(false);


    // useEffect(() => {
    //     console.log(today);
    // }, []);


    const handleUpdatePersonalInfo = (formData) => {
        const currentValues = personalInfoForm.getFieldsValue();
        const initialValues = {
            email: userInfo.email,
            nickname: userInfo.nickname,
            lastName: userInfo.lastName,
            firstName: userInfo.firstName
        };

        // Check if current form values are the same as the initial values
        const isUnchanged = Object.keys(initialValues).every(key => currentValues[key] === initialValues[key]);

        if (isUnchanged) {
            successMessage(intl.formatMessage({id: 'settingsPage.personalInfo.success'}));
            return;
        }

        setIsUpdatingPersonalInfo(true);

        axios.post(
            "/api/update_personal_info/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    personalInfo: formData,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'update_personal_info',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsUpdatingPersonalInfo(false);
            if (response.data.status === "success") {
                const updatedUserInfo = response.data.responseData;
                const newUserInfo = {
                    ...userInfo,
                    ...updatedUserInfo
                };

                setCookie('userInfo', JSON.stringify(newUserInfo), {path: '/'});
                setUserInfo(newUserInfo);
                personalInfoForm.setFieldsValue(newUserInfo);
                successMessage(intl.formatMessage({id: 'settingsPage.personalInfo.success'}));
            } else {
                errorMessage("Failed to update personal info:\n", response.data.responseData.errorMessage);
                console.error("Failed to update personal info:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingPersonalInfo(false);
            console.error("Error updating personal info:", error);
        });
    };

    const handleResetPassword = (values) => {
        if (values.password !== values.confirmPassword) {
            errorMessage(intl.formatMessage({id: 'loginPage.invalid.reset.confirm'}));
            return;
        }

        setIsUpdatingPassword(true);

        axios.post(
            "/api/reset_password/",
            {
                parameters: {
                    email: userInfo.email,
                    code: values.code,
                    password: values.password,
                    os: userInfo.os,
                    datetime: new Date()
                },
                requestName: 'reset_password',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsUpdatingPassword(false);
            if (response.data.status === "success") {
                successMessage(intl.formatMessage({id: 'loginPage.reset.success'}));
                resetPasswordForm.resetFields();
            } else if (response.data.status === "invalidCredentials") {
                errorMessage(intl.formatMessage({id: 'settingsPage.invalid.reset.credential'}));
            } else {
                console.error("Error resetting password:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingPassword(false);
            console.error("Error resetting password:", error);
        });
    };

    const handleSwitchShowLog = (checked) => {
        setIsSwitchingShowLog(true);

        axios.post(
            "/api/switch_show_log/",
            {
                parameters: {
                    userId: userInfo.userId,
                    showLog: checked,
                    os: userInfo.os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'switch_show_log',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsSwitchingShowLog(false);
            if (response.data.status === "success") {
                const updatedUserInfo = {
                    ...userInfo,
                    showLog: checked
                };
                setUserInfo(updatedUserInfo);
                setCookie('userInfo', updatedUserInfo, {path: '/'});

                if (checked) {
                    successMessage(intl.formatMessage({id: 'settingsPage.showLog.on'}));
                } else {
                    successMessage(intl.formatMessage({id: 'settingsPage.showLog.off'}));
                }
            } else {
                errorMessage("Failed to switch showing log:\n", response.data.responseData.errorMessage);
                console.error("Failed to switch showing log:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsSwitchingShowLog(false);
            errorMessage("Error switching showing log:", error);
            console.error("Error switching showing log:", error);
        });
    };


    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                navigate('/dashboard');
                break;
            case "projects":
                navigate('/project');
                break;
            case "inventory":
                navigate('/inventory');
                break;
            case "accounting":
                warningMessage(intl.formatMessage({id: "main.underDeveloping"}))
                // navigate('/accounting');
                break;
            case "settings":
                navigate('/settings');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    const handleLogout = () => {
        setCookie('userInfo', '', {path: '/', expires: new Date(0)}); // Setting a past date to delete the cookie
        navigate('/login');
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const loadingMessage = (message) => {
        messageApi.open({
            type: 'loading',
            content: message,
            key: 'loadingMessage'
        });
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}
            <Sider theme="light" style={{position: 'relative', height: '100vh'}} width={240}>
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    <FormattedMessage id="sider.title" values={{versionNumber: userInfo.versionNumber}}/>
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['settings']}
                      mode="inline"
                      onClick={handleMenuClick}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', padding: '16px', borderTop: '1px solid #f0f0f0'}}>
                    <Link onClick={handleLogout} style={{display: 'flex', alignItems: 'center'}}>
                        <LogoutOutlined/>
                        <span style={{marginLeft: '8px'}}>
                            <FormattedMessage id="sider.logout"/>
                        </span>
                    </Link>
                </div>
            </Sider>
            <Layout className="site-layout" style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
                <Content style={{margin: '0 16px', backgroundColor: '#f0f2f5'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={2}><FormattedMessage id="settingsPage.title"/></Title>
                        <DropDownMenuLanguage setLocale={setLocale}/>
                    </div>
                    <div style={{maxHeight: '90vh', overflowY: 'auto'}}>
                        <Title level={4} style={{marginTop: '0px'}}><FormattedMessage id="settingsPage.personalInfo"/></Title>
                        <Divider style={{marginTop: '0px'}}/>
                        <Form name="personal-info-form"
                              form={personalInfoForm}
                              layout={"vertical"}
                              onFinish={handleUpdatePersonalInfo}
                              style={{maxWidth: 600}}
                              initialValues={{
                                  "email": userInfo.email ?? null,
                                  "nickname": userInfo.nickname ?? null,
                                  "lastName": userInfo.lastName ?? null,
                                  "firstName": userInfo.firstName ?? null,
                              }}
                        >
                            <Form.Item label={<FormattedMessage id="settingsPage.email"/>}
                                       name="email"
                                       tooltip={<FormattedMessage id="settingsPage.email.tooltip"/>}
                            >
                                <Input disabled/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="settingsPage.nickname"/>}
                                       name="nickname"
                                       tooltip={<FormattedMessage id="settingsPage.nickname.tooltip"/>}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="settingsPage.lastname"/>} name="lastName">
                                <Input/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="settingsPage.firstname"/>} name="firstName">
                                <Input/>
                            </Form.Item>
                        </Form>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5vh'}}>
                            <Space>
                                <Button onClick={() => personalInfoForm.resetFields()}>
                                    <FormattedMessage id="settingsPage.reset"/>
                                </Button>
                                <Button type="primary" onClick={() => personalInfoForm.submit()} loading={isUpdatingPersonalInfo}>
                                    <FormattedMessage id="settingsPage.update"/>
                                </Button>
                            </Space>
                        </div>
                        <Title level={4} style={{marginTop: '0px'}}><FormattedMessage id="settingsPage.general"/></Title>
                        <Divider style={{marginTop: '0px'}}/>
                        <Form name="general-form"
                              form={generalForm}
                              style={{maxWidth: 600}}
                              initialValues={{"show-log": userInfo.showLog ?? true}}
                        >
                            <Form.Item label={<FormattedMessage id="settingsPage.showLog"/>}
                                       name="show-log"
                                       tooltip={<FormattedMessage id="settingsPage.showLog.tooltip"/>}
                            >
                                <Switch onChange={handleSwitchShowLog} loading={isSwitchingShowLog}/>
                            </Form.Item>
                        </Form>
                        <Title level={4} style={{marginTop: '0px'}}><FormattedMessage id="loginPage.reset.password"/></Title>
                        <Divider style={{marginTop: '0px'}}/>
                        <Form name="reset-password-form"
                              form={resetPasswordForm}
                              layout={"vertical"}
                              onFinish={handleResetPassword}
                              style={{maxWidth: 600}}
                        >
                            <Form.Item label={<FormattedMessage id="loginPage.password.new"/>}
                                       name="password"
                                       rules={[{required: true, message: <FormattedMessage id="loginPage.password.new.des"/>}]}
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="loginPage.password.confirm"/>}
                                       name="confirmPassword"
                                       rules={[{required: true, message: <FormattedMessage id="loginPage.password.confirm.des"/>}]}
                            >
                                <Input.Password/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="loginPage.verificationCode"/>}
                                       name="code"
                                       rules={[{required: true, message: <FormattedMessage id="loginPage.verificationCode.des"/>}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Form>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '5vh', marginBottom: '5vh'}}>
                            <Space>
                                <Button onClick={() => resetPasswordForm.resetFields()}>
                                    <FormattedMessage id="main.cancel"/>
                                </Button>
                                <Button type="primary" onClick={() => resetPasswordForm.submit()} loading={isUpdatingPassword}>
                                    <FormattedMessage id="loginPage.reset.password"/>
                                </Button>
                            </Space>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default SettingsPage;
