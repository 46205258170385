import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Layer, Map, Source} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Supercluster from 'supercluster';
import {Tooltip} from "antd";


const ClusteredMap = ({projectCoordinates}) => {
    const mapToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const [viewport, setViewport] = useState({
        latitude: 40.726808,
        longitude: -73.856748,
        zoom: 9.3
    });
    const [hoverInfo, setHoverInfo] = useState(null);
    const [clusters, setClusters] = useState([]);


    const points = useMemo(() =>
            projectCoordinates
                .filter(project => project.coordinates &&
                    Array.isArray(project.coordinates) &&
                    project.coordinates.length === 2 &&
                    project.coordinates.every(coord => coord !== null && !isNaN(coord)))
                .map(project => ({
                    type: 'Feature',
                    properties: {cluster: false, projectId: project.name, status: project.status},
                    geometry: {
                        type: 'Point',
                        coordinates: project.coordinates
                    }
                }))
        , [projectCoordinates]);

    const supercluster = useMemo(() => {
        const sc = new Supercluster({
            radius: 40,
            maxZoom: 16
        });
        sc.load(points);
        return sc;
    }, [points]);

    useEffect(() => {
        if (supercluster) {
            const newClusters = supercluster.getClusters([-180, -85, 180, 85], Math.floor(viewport.zoom));
            setClusters(newClusters);
        }
    }, [supercluster, viewport.zoom]);

    const layerStyle = {
        id: 'clusters',
        type: 'circle',
        source: 'clusters',
        filter: ['has', 'point_count'],
        paint: {
            'circle-color': '#1e76fc',
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                15,  // Size for clusters with < 10 points
                10,
                20,  // Size for clusters with < 100 points
                100,
                25   // Size for clusters with >= 100 points
            ]
        }
    };

    const clusterCountLayer = {
        id: 'cluster-count',
        type: 'symbol',
        source: 'clusters',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
            'text-size': 10
        },
        paint: {
            'text-color': '#ffffff'
        }
    };

    const unclusteredPointLayer = {
        id: 'unclustered-point',
        type: 'circle',
        source: 'clusters',
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': [
                'case',
                ['==', ['get', 'status'], 'completed'],
                '#5cc424',  // Green color for completed projects
                '#1e76fc'   // Blue color for other projects
            ],
            'circle-radius': 6,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        }
    }

    const onHover = useCallback((event) => {
        const {
            features,
            point: {x, y}
        } = event;
        const hoveredFeature = features && features[0];

        if (hoveredFeature && !hoveredFeature.properties.cluster) {
            // Get unique names at this point
            const uniqueNames = Array.from(new Set(features
                .filter(f => !f.properties.cluster)
                .map(f => f.properties.projectId)
            ));

            setHoverInfo({
                x,
                y,
                names: uniqueNames
            });
        } else {
            setHoverInfo(null);
        }
    }, []);

    return (
        <Map mapboxAccessToken={mapToken}
             {...viewport}
             onMove={evt => setViewport(evt.viewState)}
             style={{width: '100%', height: '30vh', borderRadius: '8px'}}
             mapStyle="mapbox://styles/mapbox/streets-v12"
             onMouseMove={onHover}
             interactiveLayerIds={['unclustered-point']}
        >
            <Source id="clusters"
                    type="geojson"
                    data={{
                        type: 'FeatureCollection',
                        features: clusters
                    }}
                    cluster={false}
            >
                <Layer {...layerStyle} />
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredPointLayer} />
            </Source>
            {hoverInfo && hoverInfo.names.length > 0 && (
                <div style={{position: 'absolute', left: hoverInfo.x, top: hoverInfo.y, pointerEvents: 'none'}}>
                    <Tooltip title={<>{hoverInfo.names.map(name => <div key={name}>{name}</div>)}</>}
                             open={true}
                    >
                        <div style={{width: 1, height: 1}}></div>
                    </Tooltip>
                </div>
            )}
        </Map>
    );
};

export default ClusteredMap;
