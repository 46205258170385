import React from 'react';
import {FormattedMessage} from "react-intl";


const currentYear = new Date().getFullYear().toString();


export const emptyProjectData = [
    {
        id: 'container-on-going',
        name: <FormattedMessage id="projectPage.ongoing"/>,
        items: [
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading1",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading2",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading3",
                "createToYear": currentYear,
                "percentage": {}
            }
        ]
    },
    {
        id: 'container-in-progress',
        name: <FormattedMessage id="projectPage.inProgress"/>,
        items: [
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading4",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading5",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading6",
                "createToYear": currentYear,
                "percentage": {}
            }
        ]
    },
    {
        id: 'container-temporary',
        name: <FormattedMessage id="projectPage.temporary"/>,
        items: [
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading7",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading8",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading9",
                "createToYear": currentYear,
                "percentage": {}
            }
        ]
    },
    {
        id: 'container-pending-accept',
        name: <FormattedMessage id="projectPage.pendingAccept"/>,
        items: [
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading10",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading11",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading12",
                "createToYear": currentYear,
                "percentage": {}
            }
        ]
    },
    {
        id: 'container-completed',
        name: <FormattedMessage id="projectPage.completed"/>,
        items: [
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading13",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading14",
                "createToYear": currentYear,
                "percentage": {}
            },
            {
                "name": "Loading",
                "jobSite": "Loading",
                "id": "Loading15",
                "createToYear": currentYear,
                "percentage": {}
            }
        ]
    },
];
