import React, {useState, useCallback, useMemo} from 'react';
import {Form, Image, Modal, Popconfirm, Space, Spin, Button, Input, message} from 'antd';
import {CloseOutlined, DeleteOutlined, DownloadOutlined, EyeOutlined} from '@ant-design/icons';
import {Document, Page, pdfjs} from 'react-pdf';
import {FormattedMessage, useIntl} from "react-intl";

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import pdfIcon from "../icons/pdf-icon.png";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();


const PDFPreview = ({file, downloadProjectFile, deleteProjectFiles, isDeletingFile}) => {
    const intl = useIntl();
    const [messageApi, contextHolder] = message.useMessage();
    const [numPages, setNumPages] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [inputPasswordOpen, setInputPasswordOpen] = useState(false);
    const [password, setPassword] = useState('');

    const handleDownload = useCallback(() => {
        downloadProjectFile(file);
    }, [downloadProjectFile, file]);

    const handleDelete = useCallback(() => {
        deleteProjectFiles(file);
    }, [deleteProjectFiles, file]);

    const onDocumentLoadSuccess = useCallback(({numPages}) => {
        setNumPages(numPages);
    }, []);

    const documentOptions = useMemo(() => ({
        password
    }), [password]);

    const handlePasswordSubmit = useCallback((values) => {
        setPassword(values.password);
        setInputPasswordOpen(false);
    }, []);

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    return (
        <div>
            {contextHolder}
            <Image src={pdfIcon}
                   width={200}
                   height={200}
                   style={{objectFit: 'cover'}}
                   preview={{
                       visible: false,
                       onVisibleChange: (visible) => {
                           if (visible) {
                               setPreviewOpen(true);
                           }
                       },
                       mask: (
                           <div style={{textAlign: 'center'}}>
                            <span style={{display: 'block', paddingLeft: '0px'}}>
                                <EyeOutlined/> <FormattedMessage id="main.previewFile"/>
                            </span>
                               <span style={{display: 'block', paddingLeft: '0px'}}>
                                {file.name.split('-').slice(1).join('-')}
                            </span>
                           </div>
                       ),
                   }}
            />
            <Modal open={previewOpen}
                   footer={null}
                   title={null}
                   onCancel={() => setPreviewOpen(false)}
                   width={'700px'}
                   style={{textAlign: 'center', top: 20}}
                   destroyOnClose
                   closable={false}
            >
                <Document file={file.url}
                          onLoadSuccess={onDocumentLoadSuccess}
                          loading={<Spin/>}
                          options={documentOptions}
                          onPassword={(callback, reason) => {
                              setInputPasswordOpen(true);
                              if (reason === 2) {
                                  warningMessage(<FormattedMessage id="main.inputFilePassword.incorrect"/>);
                              }
                          }}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <div key={`page_${index + 1}`} style={{display: 'flex', justifyContent: 'center'}}>
                            <Page pageNumber={index + 1} width={700}/>
                        </div>
                    ))}
                    <div style={{
                        position: 'fixed',
                        top: 30,
                        right: 30,
                        zIndex: 1040,
                        background: '#fff',
                        borderRadius: '50%',
                        width: 40,
                        height: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }} onClick={() => setPreviewOpen(false)}>
                        <CloseOutlined/>
                    </div>
                    <Space size={12} className="toolbar-wrapper" style={{marginTop: '20px', zIndex: 1040, backgroundColor: 'black', color: '#fff', opacity: 0.5}}>
                        <DownloadOutlined onClick={handleDownload} style={{cursor: 'pointer'}}/>
                        <Popconfirm title={intl.formatMessage({id: "warning.confirmDelete"})}
                                    onConfirm={handleDelete}
                                    okButtonProps={{loading: isDeletingFile}}
                        >
                            <DeleteOutlined/>
                        </Popconfirm>
                    </Space>
                </Document>
            </Modal>
            <Modal open={inputPasswordOpen}
                   title={intl.formatMessage({id: "main.inputFilePassword"})}
                   onCancel={() => {
                       setInputPasswordOpen(false);
                       setPreviewOpen(false);
                       setPassword('');
                   }}
                   destroyOnClose
                   centered
                   footer={null}
            >
                <Form onFinish={handlePasswordSubmit}>
                    <Form.Item name="password"
                               rules={[{required: true, message: <FormattedMessage id="main.inputFilePassword"/>}]}
                    >
                        <Input.Password placeholder={intl.formatMessage({id: "main.inputFilePassword"})}/>
                    </Form.Item>
                    <div style={{textAlign: 'right'}}>
                        <Space>
                            <Button onClick={() => {
                                setInputPasswordOpen(false);
                                setPreviewOpen(false);
                                setPassword('');
                            }}>
                                <FormattedMessage id="main.cancel"/>
                            </Button>
                            <Button key="submit" type="primary" htmlType="submit">
                                <FormattedMessage id="main.submit"/>
                            </Button>
                        </Space>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default PDFPreview;
