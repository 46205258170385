import React from 'react';
import {Navigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';

const PrivateRoute = ({children}) => {
    const [cookies] = useCookies(['userInfo']);
    const userInfo = cookies.userInfo || null;

    if (!userInfo) {
        // Redirect them to the login page
        return <Navigate to="/login"/>;
    }

    return children;
};

export default PrivateRoute;
