import React, {useState} from 'react';
import {Layout, Menu, Typography} from 'antd';
import {
    AccountBookOutlined,
    FundProjectionScreenOutlined,
    PieChartOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Title} = Typography;

function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem('Dashboard', 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem('Projects', 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem('Storages', 'storages', <ShopOutlined/>),
    getSiderMenuItem('Accounting', 'accounting', <AccountBookOutlined/>),
];

const AccountingPage = () => {
    const navigate = useNavigate();

    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                navigate('/dashboard');
                break;
            case "projects":
                navigate('/project');
                break;
            case "storages":
                navigate('/storage');
                break;
            case "accounting":
                navigate('/accounting');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider theme="light">
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    Innocore System
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['accounting']}
                      mode="inline"
                      onClick={handleMenuClick}>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Content style={{margin: '0 16px'}}>
                    <Title level={2}>Accounting Page</Title>
                </Content>
            </Layout>
        </Layout>
    );
};

export default AccountingPage;
