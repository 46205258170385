import {Button, Empty, Flex, Image, message, Popconfirm, Skeleton, Space} from "antd";
import {
    DeleteOutlined,
    DownloadOutlined, EyeOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    UndoOutlined,
    UploadOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import PDFPreview from "./PDFPreview";
import React, {useEffect, useRef, useState} from "react";


const fileTypes = {
    'all': <FormattedMessage id="main.allFiles"/>,
    'contracts': <FormattedMessage id="main.contracts"/>,
    'drawings': <FormattedMessage id="main.drawings"/>,
    'materials': <FormattedMessage id="main.materials"/>,
    'doors': <FormattedMessage id="main.elevatorDoors"/>,
    'elevators': <FormattedMessage id="main.elevators"/>,
    'construction': <FormattedMessage id="main.constructionStatus"/>,
    'others': <FormattedMessage id="main.otherFiles"/>,
};


const FileManager = ({userInfo, id, showUploadButton, isRenderingFiles, isDeletingFile, fileList, onUploadFiles, onDownloadFile, onDeleteFile}) => {
    const intl = useIntl();
    const fileInputRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [viewFileType, setViewFileType] = useState('all');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [initialFileList, setInitialFileList] = useState(fileList);


    useEffect(() => {
        setInitialFileList(fileList);
    }, [fileList]);


    const filteredFileTypes = userInfo.group === 'office'
        ? Object.fromEntries(Object.entries(fileTypes).filter(([key]) => key !== 'contracts'))
        : fileTypes;

    const handleFileUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onFileChange = (event) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'];
        const files = Array.from(event.target.files).filter(file => allowedTypes.includes(file.type));

        const newUploadedFiles = files.map(file => ({
            uid: `${id}/${file.name}`,
            name: `${viewFileType}-${file.name}`,
            status: 'done',
            file,
            url: URL.createObjectURL(file),
        }));

        // Check for duplicate file names
        const existingFileNames = initialFileList.map(file => file.name);
        const duplicateFiles = newUploadedFiles.filter(file => existingFileNames.includes(file.name));

        if (duplicateFiles.length > 0) {
            const duplicateFileNames = duplicateFiles.map(file => file.name.split('-').slice(1).join('-')).join(', ');
            warningMessage(intl.formatMessage({id: "warning.duplicatedNames"}, {duplicateFileNames: duplicateFileNames}));
            event.target.value = '';
            return;
        }

        setInitialFileList([...initialFileList, ...newUploadedFiles]);
        event.target.value = '';

        onUploadFiles(newUploadedFiles, viewFileType);
    };

    const handlePreview = async (file) => {
        setPreviewImage(file.url);
        setPreviewOpen(true);
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <div style={{height: '70vh'}}>
            {contextHolder}
            <Space size={"large"}>
                <Flex wrap="wrap" gap="middle">
                    {Object.entries(filteredFileTypes).map(([key, value]) => (
                        <Button key={key}
                                type={viewFileType === key ? 'primary' : 'text'}
                                onClick={() => setViewFileType(key)}
                                size='middle'
                        >
                            {value}
                        </Button>
                    ))}
                </Flex>
                {showUploadButton && (
                    <Button type="dashed"
                            icon={<UploadOutlined/>}
                            disabled={viewFileType === 'all'}
                            onClick={handleFileUpload}
                    >
                        <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.uploadFiles"/></span>
                    </Button>
                )}
                <input type="file" ref={fileInputRef} style={{display: 'none'}} onChange={onFileChange} multiple/>
            </Space>
            <div style={{display: 'flex', flexWrap: 'wrap', marginTop: '20px', maxHeight: '65vh', overflowY: 'auto'}}>
                {isRenderingFiles ? (
                        Array.from({length: 5}).map((_, index) => (
                            <Skeleton.Image key={index}
                                            active
                                            style={{width: 200, height: 200, margin: '5px'}}
                            />
                        ))
                    ) :
                    initialFileList
                        .filter(file => viewFileType === 'all' || file.name.startsWith(`${viewFileType}-`))
                        .length === 0 ? (<Empty style={{width: '100%', marginTop: '10vh'}}/>
                    ) : (
                        initialFileList
                            .filter(file => viewFileType === 'all' || file.name.startsWith(`${viewFileType}-`))
                            .map(file => (
                                <div key={file.uid} style={{margin: '5px'}}>
                                    {file.name.endsWith('.pdf') ? (
                                        <PDFPreview file={file}
                                                    downloadProjectFile={onDownloadFile}
                                                    deleteProjectFiles={onDeleteFile}
                                                    isDeletingFile={isDeletingFile}
                                        />
                                    ) : (
                                        <Image src={file.url}
                                               width={200}
                                               height={200}
                                               style={{objectFit: 'cover'}}
                                               onClick={() => handlePreview(file)}
                                               preview={{
                                                   visible: previewOpen && previewImage === file.url,
                                                   onVisibleChange: (visible) => {
                                                       if (!visible) {
                                                           setPreviewOpen(false);
                                                           setPreviewImage('');
                                                       }
                                                   },
                                                   toolbarRender: (
                                                       _,
                                                       {
                                                           transform: {scale},
                                                           actions: {
                                                               onFlipY,
                                                               onFlipX,
                                                               onRotateLeft,
                                                               onRotateRight,
                                                               onZoomOut,
                                                               onZoomIn,
                                                               onReset,
                                                           },
                                                       },
                                                   ) => (
                                                       <Space size={12} className="toolbar-wrapper">
                                                           <DownloadOutlined onClick={() => onDownloadFile(file)}/>
                                                           <SwapOutlined rotate={90} onClick={onFlipY}/>
                                                           <SwapOutlined onClick={onFlipX}/>
                                                           <RotateLeftOutlined onClick={onRotateLeft}/>
                                                           <RotateRightOutlined onClick={onRotateRight}/>
                                                           <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut}/>
                                                           <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn}/>
                                                           <UndoOutlined onClick={onReset}/>
                                                           {showUploadButton &&
                                                               <Popconfirm title={intl.formatMessage({id: "warning.confirmDelete"})}
                                                                           onConfirm={() => onDeleteFile(file)}
                                                                           okButtonProps={{loading: isDeletingFile}}
                                                               >
                                                                   <DeleteOutlined/>
                                                               </Popconfirm>
                                                           }
                                                       </Space>
                                                   ),
                                                   mask: (
                                                       <div style={{textAlign: 'center'}}>
                                                           <span style={{display: 'block', paddingLeft: '0px'}}>
                                                               <EyeOutlined/> <FormattedMessage id="main.previewFile"/>
                                                           </span>
                                                           <span style={{display: 'block', paddingLeft: '0px'}}>
                                                               {file.name.split('-').slice(1).join('-')}
                                                           </span>
                                                       </div>
                                                   )
                                               }}
                                        />
                                    )}
                                </div>
                            ))
                    )}
            </div>
        </div>
    );
};

export default FileManager;
