import React from 'react';
import {TranslationOutlined} from '@ant-design/icons';
import {Button, Dropdown, Space} from 'antd';


const languageItems = [
    {label: '中文', key: 'zh'},
    {label: 'English', key: 'en'}
];


const DropDownMenuLanguage = ({setLocale}) => {

    const handleLanguageMenuClick = (e) => {
        setLocale(e.key);
    };

    const languageMenuProps = {
        items: languageItems,
        onClick: handleLanguageMenuClick,
    };

    return (
        <Space wrap>
            <Dropdown menu={languageMenuProps}>
                <Button>
                    <Space>
                        <TranslationOutlined/>
                    </Space>
                </Button>
            </Dropdown>
        </Space>
    );
};

export default DropDownMenuLanguage;
