import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import '../projectPage.css'

const Container = ({id, children}) => {
    const {
        attributes,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id: id,
        data: {
            type: 'container',
        },
    });

    return (
        <div className="container-board"
             {...attributes}
             ref={setNodeRef}
             style={{
                 transition,
                 transform: CSS.Translate.toString(transform),
                 maxHeight: '80vh',
                 overflowY: 'auto'
             }}
        >
            {children}
        </div>
    );
};

export default Container;
