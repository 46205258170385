import React, {useState} from 'react';
import {Button, DatePicker, Form, Input, List, Modal, Popconfirm} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {FormattedMessage} from "react-intl";


const ProjectMemoList = ({memos, isFetchingMemos, isAddingMemo, isEditingMemo, isDeletingMemo, onAddMemo, onEditMemo, onDeleteMemo, showAddMemoButton}) => {
    const [showModal, setShowModal] = useState(false);
    const [memoForm] = Form.useForm();
    const [editingMemoId, setEditingMemoId] = useState(null);


    const handleEdit = (memo) => {
        setEditingMemoId(memo.memoId);
        memoForm.setFieldsValue({
            memoDate: dayjs(memo.memoDate),
            content: memo.content,
        });
        setShowModal(true);
    };

    const handleAddEdit = (values) => {
        if (editingMemoId) {
            onEditMemo(
                editingMemoId,
                {
                    memoDate: new Date(values.memoDate),
                    content: values.content
                }
            );
        } else {
            onAddMemo(values);
        }
        setShowModal(false);
        memoForm.resetFields();
        setEditingMemoId(null);
    };

    return (
        <div style={{height: "50vh"}}>
            {showAddMemoButton &&
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '20px'}}>
                    <Button type="primary"
                            icon={<PlusOutlined/>}
                            onClick={() => setShowModal(true)}
                    >
                        <FormattedMessage id="projectPage.addMemo"/>
                    </Button>
                </div>
            }

            <List loading={isFetchingMemos}
                  itemLayout="horizontal"
                  dataSource={memos}
                  renderItem={(memo) => (
                      <List.Item actions={showAddMemoButton ? [
                          <Button icon={<EditOutlined/>} onClick={() => handleEdit(memo)}/>,
                          <Popconfirm title={<FormattedMessage id="warning.confirmDeleteMemo"/>}
                                      onConfirm={() => onDeleteMemo(memo.memoId)}
                                      okButtonProps={{loading: isDeletingMemo}}
                          >
                              <Button icon={<DeleteOutlined/>} danger/>
                          </Popconfirm>
                      ] : []}
                      >
                          <List.Item.Meta title={dayjs(memo.memoDate).format('YYYY-MM-DD')}
                                          description={memo.content}
                          />
                      </List.Item>
                  )}
                  style={{height: '45vh', overflowY: 'auto'}}
            />

            <Modal title={editingMemoId ? <FormattedMessage id="projectPage.editMemo"/> : <FormattedMessage id="projectPage.addMemo"/>}
                   open={showModal}
                   onCancel={() => {
                       setShowModal(false);
                       memoForm.resetFields();
                       setEditingMemoId(null);
                   }}
                   okText={editingMemoId ? <FormattedMessage id="projectPage.updateMemo"/> : <FormattedMessage id="projectPage.addMemo"/>}
                   onOk={() => memoForm.submit()}
                   okButtonProps={{loading: editingMemoId ? isEditingMemo : isAddingMemo}}
                   centered
                   width="500px"
            >
                <Form form={memoForm} onFinish={handleAddEdit} layout="vertical">
                    <Form.Item name="memoDate"
                               label={<FormattedMessage id="main.date"/>}
                               rules={[{required: true, message: <FormattedMessage id="projectPage.date.req"/>}]}
                    >
                        <DatePicker style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item name="content"
                               label={<FormattedMessage id="projectPage.memoContent"/>}
                               rules={[{required: true, message: <FormattedMessage id="projectPage.memoContent.req"/>}]}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ProjectMemoList;
