import React, {useEffect, useState} from 'react';
import {IntlProvider} from 'react-intl';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import LoginPage from "./loginPage";
import ProjectPage from './projectPage';
import InventoryPage from './inventoryPage';
import AccountingPage from './accountingPage';
import DashboardPage from "./dashboardPage";
import PrivateRoute from "./privateRoute";
import SettingsPage from "./settingsPage";

import EnglishMessages from './lang/en.json';
import ChineseMessages from './lang/zh.json';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en';

import {ConfigProvider} from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';


function App() {
    const [locale, setLocale] = useState('zh');
    const messages = {en: EnglishMessages, zh: ChineseMessages};
    const antdLocales = {en: enUS, zh: zhCN};

    useEffect(() => {
        dayjs.locale(locale === 'en' ? 'en' : 'zh-cn');
    }, [locale]);

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <ConfigProvider locale={antdLocales[locale]}>
                <Router>
                    <Routes>
                        <Route path="/login" element={<LoginPage setLocale={setLocale}/>}/>
                        <Route path="/dashboard" element={<PrivateRoute><DashboardPage setLocale={setLocale}/></PrivateRoute>}/>
                        <Route path="/project" element={<PrivateRoute><ProjectPage setLocale={setLocale}/></PrivateRoute>}/>
                        <Route path="/inventory" element={<PrivateRoute><InventoryPage setLocale={setLocale}/></PrivateRoute>}/>
                        <Route path="/accounting" element={<PrivateRoute><AccountingPage/></PrivateRoute>}/>
                        <Route path="/settings" element={<PrivateRoute><SettingsPage setLocale={setLocale}/></PrivateRoute>}/>
                        <Route path="/" element={<Navigate replace to="/login" setLocale={setLocale}/>}/>
                    </Routes>
                </Router>
            </ConfigProvider>
        </IntlProvider>
    );
}

export default App;
