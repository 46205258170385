import React, {useEffect, useState} from 'react';
import {AutoComplete, Input} from 'antd';
import mapboxSdk from '@mapbox/mapbox-sdk';
import mapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import {useIntl} from "react-intl";


const NYCCoordinates = [-73.9857, 40.7484];


const AddressAutocomplete = ({mapToken, onSelectAddress, initialValue}) => {
    const intl = useIntl();
    const mapboxClient = mapboxSdk({accessToken: mapToken});
    const geocodeClient = mapboxGeocoding(mapboxClient);
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (initialValue) {
            setInputValue(initialValue);
        }
    }, [initialValue]);

    const handleSearch = (value) => {
        setInputValue(value);

        if (!value || value.length < 3) {
            setSuggestions([]);
            return;
        }

        geocodeClient.forwardGeocode({
            types: ["address"],
            countries: ["US"],
            query: value,
            proximity: NYCCoordinates,
            autocomplete: true,
            limit: 5
        })
            .send()
            .then(response => {
                const matches = response.body.features.map(feature => {
                    const addressParts = feature.place_name.split(',');
                    const addressWithoutCountry = addressParts.slice(0, -1).join(',');
                    return {
                        value: addressWithoutCountry,
                        label: addressWithoutCountry,
                        shortAddress: addressParts[0],
                        cityName: addressParts[1],
                        coordinates: feature.center
                    };
                });
                setSuggestions(matches);
            });
    };

    const onSelect = (value, option) => {
        setInputValue(option.label);
        onSelectAddress(option);
    };

    const onChange = (value) => {
        setInputValue(value);
        const addressParts = value.split(',');
        onSelectAddress({
            value: value,
            label: value,
            shortAddress: addressParts[0],
            cityName: addressParts[1],
            coordinates: null
        });
    };

    return (
        <AutoComplete style={{width: '100%'}}
                      value={inputValue}
                      options={suggestions}
                      onSelect={onSelect}
                      onSearch={handleSearch}
                      onChange={onChange}
        >
            <Input placeholder={intl.formatMessage({id: "projectPage.jobSite.req"})}/>
        </AutoComplete>
    );
};

export default AddressAutocomplete;
