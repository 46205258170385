import React, {useEffect, useRef, useState} from 'react';
import Draggable from 'react-draggable';
import {AutoComplete, Button, DatePicker, Flex, Input, InputNumber, Layout, Menu, message, Modal, Popconfirm, Space, Table, Tag, Typography} from 'antd';
import {
    AccountBookOutlined,
    AppstoreAddOutlined,
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    FundProjectionScreenOutlined,
    HistoryOutlined,
    LogoutOutlined,
    PieChartOutlined,
    PlusOutlined,
    SaveOutlined,
    SettingOutlined,
    ShopOutlined,
    SyncOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from "react-intl";
import DropDownMenuLanguage from "./components/DropDownMenuLanguage";
import axios from "axios";
import dayjs from 'dayjs';
import {v4 as uuidv4} from "uuid";
import commandIcon from "./icons/icons8-command-96.png";
import ctrlIcon from "./icons/icons8-ctrl-96.png";
import fIcon from "./icons/icons8-f-key-96.png";
import {useCookies} from "react-cookie";

dayjs.extend(require('dayjs/plugin/utc'))
dayjs.extend(require('dayjs/plugin/timezone'))

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;
const {Title, Link} = Typography;
const {TextArea} = Input;

function getSiderMenuItem(label, key, icon, children, type) {
    return {key, icon, children, label, type};
}

const siderMenuItems = [
    getSiderMenuItem(<FormattedMessage id="sider.dashboard"/>, 'dashboard', <PieChartOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.projects"/>, 'projects', <FundProjectionScreenOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.inventory"/>, 'inventory', <ShopOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.accounting"/>, 'accounting', <AccountBookOutlined/>),
    getSiderMenuItem(<FormattedMessage id="sider.settings"/>, 'settings', <SettingOutlined/>),
];

const vendorOptions = [
    {value: 'KINGS ELEVATOR PARTS INC.', label: 'KINGS ELEVATOR PARTS INC.'},
    {value: 'ZZIPCO', label: 'ZZIPCO'},
    {value: 'FLAME CUT STEEL INC.', label: 'FLAME CUT STEEL INC.'},
    {value: 'MEGA ELEVATOR PARTS', label: 'MEGA ELEVATOR PARTS'},
    {value: 'XPRESS DOOR-N-CABS', label: 'XPRESS DOOR-N-CABS'},
    {value: 'TANNER BOLT N NUT INC.', label: 'TANNER BOLT N NUT INC.'},
    {value: 'HOLLISTER WHITNEY', label: 'HOLLISTER WHITNEY'},
    {value: 'GAL', label: 'GAL'},
    {value: 'ESI', label: 'ESI'},
    {value: 'PELLE', label: 'PELLE'},
    {value: 'THE HOME DEPOT', label: 'THE HOME DEPOT'},
    {value: 'Amazon', label: 'Amazon'},
    {value: 'VANTAGE ELEVATION', label: 'VANTAGE ELEVATION'},
    {value: 'Smartrise Engineering', label: 'Smartrise Engineering'},
    {value: 'Canton Elevator', label: 'Canton Elevator'},
    {value: 'Kwong Ming Lighting n Furnature Inc.', label: 'Kwong Ming Lighting n Furnature Inc.'},
    {value: 'Lendy Electric', label: 'Lendy Electric'},
    {value: 'Elevator Products Corp', label: 'Elevator Products Corp'},
    {value: 'DBA JANUS ELEVATOR PRODUCTS', label: 'DBA JANUS ELEVATOR PRODUCTS'},
    {value: 'Giant Lift', label: 'Giant Lift'},
    {value: 'PFLOW INDUSTRIES', label: 'PFLOW INDUSTRIES'},
    {value: 'AUTOZONE', label: 'AUTOZONE'},
    {value: 'DIGIKEY', label: 'DIGIKEY'},
    {value: 'COLLEGE POINT ELEC SUPPLIES', label: 'COLLEGE POINT ELEC SUPPLIES'},
    {value: 'ZORO', label: 'ZORO'},
    {value: 'AIRWELD', label: 'AIRWELD'},
    {value: 'The Peelle Company Ltd.', label: 'The Peelle Company Ltd.'},
    {value: 'LOWE', label: 'LOWE'},
    {value: 'Savaria USA Inc', label: 'Savaria USA Inc'},
    {value: 'GRAINGER', label: 'GRAINGER'},
    {value: 'Certified elevator Escalator Products', label: 'Certified elevator Escalator Products'},
    {value: 'QB11 ELECTRICAL SUPPLY INC.', label: 'QB11 ELECTRICAL SUPPLY INC.'},
    {value: 'Royal Metal Products USA Corp.', label: 'Royal Metal Products USA Corp.'},
];


const InventoryPage = ({setLocale}) => {
    const navigate = useNavigate();
    const intl = useIntl(); // Hook to get the intl object
    const tableRef = useRef(null);
    const orderTableRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [cookies, setCookie] = useCookies(['userInfo']);
    const [userInfo, setUserInfo] = useState(cookies.userInfo || {});

    const [csrfToken, setCsrfToken] = useState(null);
    const [inventoryData, setInventoryData] = useState([]);
    const [selectMaterialType, setSelectMaterialType] = useState('Elevator Parts');
    const [selectOrderMaterialType, setSelectOrderMaterialType] = useState('Elevator Parts');
    const [isFetchingData, setIsFetchingData] = useState(false);
    const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
    const [isDeletingOrder, setIsDeletingOrder] = useState(false);

    const [orderData, setOrderData] = useState([]);
    const [orderNumber, setOrderNumber] = useState(null);
    const [orderQuantities, setOrderQuantities] = useState([]);
    const [inputFieldsVisible, setInputFieldsVisible] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isDeleteMaterialDisabled, setIsDeleteMaterialDisabled] = useState(false);
    const [isEditingOrderRow, setIsEditingOrderRow] = useState(false);

    const [searchVendorText, setSearchVendorText] = useState('');
    const [filteredVendorOptions, setFilteredVendorOptions] = useState(vendorOptions);

    const [showSubmitOrderModal, setShowSubmitOrderModal] = useState(false);
    const [showTrackOrderModal, setShowTrackOrderModal] = useState(false);
    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
    const [showOrderTableEditModal, setShowOrderTableEditModal] = useState(false);  // Modal for one single order
    const [editingOrderRow, setEditingOrderRow] = useState(null);  // Update singe item in one single order
    const [originalOrderData, setOriginalOrderData] = useState({});  // Store the original order data, if cancel

    const [tableHeight, setTableHeight] = useState(500);
    const [exportAllOrders, setExportAllOrders] = useState(false);

    const [dragDisabled, setDragDisabled] = useState(true);
    const [modalPosition, setModalPosition] = useState({x: 0, y: 0});
    const draggleRef = useRef(null);


    useEffect(() => {
        if (exportAllOrders) {
            handleOrderExport('view');
            setExportAllOrders(false); // Reset the flag after exporting
        }
    }, [selectedOrderDetails, exportAllOrders]);

    const updateTableHeight = () => {
        const parentHeight = orderTableRef.current?.parentElement?.offsetHeight || window.innerHeight;
        const newTableHeight = parentHeight * 0.57;
        setTableHeight(newTableHeight);
    };

    useEffect(() => {
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);

        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);


    const materialTypes = {
        'Elevator Parts': <FormattedMessage id="materialTable.elevatorParts"/>,
        'Materials': <FormattedMessage id="materialTable.materials"/>,
        'Consumables': <FormattedMessage id="materialTable.consumables"/>,
        'Tools': <FormattedMessage id="materialTable.tools"/>,
        'Others': <FormattedMessage id="materialTable.others"/>,
    };

    const columns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'productCode', key: 'productCode', width: 80, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 250, fixed: 'left',},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {title: <FormattedMessage id="inventoryPage.costPrice"/>, dataIndex: 'costPrice', key: 'costPrice', width: 80},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'orderCostPrice',
            key: 'orderCostPrice',
            width: 100,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const costPrice = item.costPrice ?? 0;
                return inputFieldsVisible[record.key] ? (
                    <InputNumber value={costPrice}
                                 min={0}
                                 onChange={(value) => handleCostPriceChange(record.key, value)}
                    />
                ) : costPrice;
            },
        },
        {title: <FormattedMessage id="inventoryPage.retailPrice"/>, dataIndex: 'retailPrice', key: 'retailPrice', width: 80},
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'orderRetailPrice',
            key: 'orderRetailPrice',
            width: 100,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const retailPrice = item.retailPrice ?? 0;
                return inputFieldsVisible[record.key] ? (
                    <InputNumber value={retailPrice}
                                 min={0}
                                 onChange={(value) => handleSubmitOrderFieldChange(record.key, 'retailPrice', value)}
                    />
                ) : retailPrice;
            },
        },
        {title: <FormattedMessage id="inventoryPage.stockLowerLimit"/>, dataIndex: 'stockLowerLimit', key: 'stockLowerLimit', width: 100},
        {title: <FormattedMessage id="inventoryPage.stockUpperLimit"/>, dataIndex: 'stockUpperLimit', key: 'stockUpperLimit', width: 100},
        {title: <FormattedMessage id="inventoryPage.inStockQuantity"/>, dataIndex: 'inStockQuantity', key: 'inStockQuantity', width: 100},
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 80},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 180,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                return inputFieldsVisible[record.key] ? (
                    <AutoComplete options={filteredVendorOptions}
                                  value={item.vendor}
                                  onChange={(value) => handleSubmitOrderFieldChange(record.key, 'vendor', value)}
                                  onSearch={handleVendorSearch}
                                  style={{width: '100%'}}
                                  placeholder={intl.formatMessage({id: "inventoryPage.selectVendor"})}
                    />
                ) : item.vendor;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 180,
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                return inputFieldsVisible[record.key] ? (
                    <TextArea value={item.memo}
                              onChange={(e) => handleSubmitOrderFieldChange(record.key, 'memo', e.target.value)}
                              placeholder={intl.formatMessage({id: "inventoryPage.enterMemo"})}
                              autoSize
                    />
                ) : item.memo;
            },
        },
        {
            title: <FormattedMessage id="inventoryPage.quantity"/>,
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            width: 140,
            fixed: 'right',
            render: (_, record) => {
                const item = orderQuantities.find(item => item.itemId === record.key) || {};
                const quantity = item.orderQuantity ?? null;

                return (
                    <>
                        {inputFieldsVisible[record.key] && (
                            <Space>
                                <InputNumber min={0}
                                             value={quantity}
                                             onChange={(value) => handleSubmitOrderFieldChange(record.key, 'orderQuantity', value)}
                                />
                                <Button icon={<DeleteOutlined/>}
                                        size="small"
                                        onClick={(value) => handleQuantityDelete(record.key, value)}
                                />
                            </Space>
                        )}
                        {!inputFieldsVisible[record.key] && (
                            <Button icon={<PlusOutlined/>}
                                    size="small"
                                    onClick={() => toggleNumberInput(record.key, true)}
                            />
                        )}
                    </>
                );
            },
        }
    ];

    // Table columns for orders
    const orderDetailColumns = [
        {title: <FormattedMessage id="materialTable.orderNumber"/>, dataIndex: 'orderNumber'},
        {
            title: <FormattedMessage id="materialTable.orderDate"/>,
            dataIndex: 'orderDate',
            width: 220,
            key: 'orderDate',
            render: (text) => dayjs.utc(text).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')
        },
        {title: <FormattedMessage id="materialTable.creator"/>, dataIndex: 'creatorName'},
        {
            title: <FormattedMessage id="materialTable.status"/>,
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => (
                record.complete ? (
                    <Tag icon={<CheckCircleOutlined/>} color="success">
                        <FormattedMessage id="main.completed"/>
                    </Tag>
                ) : (
                    <Tag icon={<SyncOutlined spin/>} color="processing">
                        <FormattedMessage id="main.inProgress"/>
                    </Tag>
                )
            )
        },
        {
            title: <FormattedMessage id="main.actions"/>,
            dataIndex: 'editAction',
            key: 'editAction',
            render: (_, record) => (
                <>
                    <Link onClick={() => {
                        handleSetOrderTableDetail(record.orderNumber);
                        setShowOrderTableEditModal(true);
                    }}>
                        <FormattedMessage id="main.edit"/>
                    </Link>
                    <span> | </span>
                    <Link onClick={() => {
                        handleSetOrderTableDetail(record.orderNumber);
                        setExportAllOrders(true);
                    }}>
                        <FormattedMessage id="main.exportAll"/>
                    </Link>
                    <span> | </span>
                    <Popconfirm title={intl.formatMessage({id: "warning.confirmDelete"})}
                                onConfirm={handleOrderDelete}
                                okButtonProps={{loading: isDeletingOrder}}
                    >
                        <Link disabled={hasProgressGreaterOrEqualOne(record.orderNumber)}
                              onClick={() => setSelectedOrderNumber(record.orderNumber)}>
                            <FormattedMessage id="main.delete"/>
                        </Link>
                    </Popconfirm>
                </>
            )
        }
    ];

    // Table columns for one single order
    const orderDetailTableColumns = [
        {title: <FormattedMessage id="inventoryPage.productCode"/>, dataIndex: 'itemId', key: 'itemId', width: 80, fixed: 'left'},
        {title: <FormattedMessage id="inventoryPage.productName"/>, dataIndex: 'productName', key: 'productName', width: 240, fixed: 'left'},
        {title: <FormattedMessage id="inventoryPage.specification"/>, dataIndex: 'specification', key: 'specification', width: 250},
        {
            title: <FormattedMessage id="inventoryPage.costPrice"/>,
            dataIndex: 'costPrice',
            key: 'costPrice',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={0}
                                 value={record.costPrice}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'costPrice', value)}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.retailPrice"/>,
            dataIndex: 'retailPrice',
            key: 'retailPrice',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={0}
                                 value={record.retailPrice}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'retailPrice', value)}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="materialTable.quantity"/>,
            dataIndex: 'orderQuantity',
            key: 'orderQuantity',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={1}
                                 value={record.orderQuantity}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'orderQuantity', value)}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.totalPrice"/>,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            width: 120,
            render: (text, record) => (record.orderQuantity * record.retailPrice).toFixed(2),
        },
        {
            title: <FormattedMessage id="materialTable.progress"/>,
            dataIndex: 'progress',
            key: 'progress',
            width: 120,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <InputNumber min={0}
                                 max={record.orderQuantity}
                                 value={record.progress}
                                 onChange={(value) => handleEditOrderFieldChange(record.itemId, 'progress', value)}
                    />
                ) : (
                    text
                )
            )
        },
        {title: <FormattedMessage id="inventoryPage.unit"/>, dataIndex: 'unit', key: 'unit', width: 60},
        {
            title: <FormattedMessage id="inventoryPage.vendor"/>,
            dataIndex: 'vendor',
            key: 'vendor',
            width: 150,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <AutoComplete options={filteredVendorOptions}
                                  value={record.vendor}
                                  onChange={(value) => handleEditOrderFieldChange(record.itemId, 'vendor', value)}
                                  onSearch={handleVendorSearch}
                                  placeholder={intl.formatMessage({id: "inventoryPage.selectVendor"})}
                                  style={{width: '100%'}}
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.memo"/>,
            dataIndex: 'memo',
            key: 'memo',
            width: 150,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <TextArea value={record.memo}
                              onChange={(e) => handleEditOrderFieldChange(record.itemId, 'memo', e.target.value)}
                              autoSize
                    />
                ) : (
                    text
                )
            )
        },
        {
            title: <FormattedMessage id="inventoryPage.arrivalDate"/>,
            dataIndex: 'arrivalDate',
            key: 'arrivalDate',
            width: 150,
            render: (text, record) => (
                editingOrderRow === record.itemId ? (
                    <DatePicker value={record.arrivalDate ? dayjs(record.arrivalDate) : null}
                                onChange={(date) => handleEditOrderFieldChange(record.itemId, 'arrivalDate', date ? date.startOf('day').format('YYYY-MM-DD') : null)}
                    />
                ) : (
                    record.arrivalDate ? dayjs(record.arrivalDate).format('YYYY-MM-DD') : ''
                )
            )
        }
    ];

    const fullInventoryColumns = columns.map((item, index) => ({
        ...item,
        hidden: index === 4 || index === 6 || index === 11 || index === 12 || index === 13,
    }));

    const orderColumns = columns.map((item, index) => ({
        ...item,
        hidden: index === 3 || index === 5
    }));

    // Create orders
    const toggleNumberInput = (itemId, show) => {
        setInputFieldsVisible(prev => ({...prev, [itemId]: show}));
        if (!orderQuantities.find(item => item.itemId === itemId)) {
            const item = inventoryData.find(item => item.key === itemId);
            if (item) {
                setOrderQuantities(prev => [
                    ...prev,
                    {
                        itemId,
                        key: itemId,
                        orderQuantity: 1,
                        costPrice: 0,
                        retailPrice: 0,
                        progress: 0,
                        productName: item.productName,
                        specification: item.specification,
                        unit: item.unit,
                        deletable: true
                    }
                ]);
            }
        }
    };

    const handleSubmitOrderFieldChange = (itemId, field, value) => {
        setOrderQuantities(prev => prev.map(item =>
            item.itemId === itemId ? {...item, [field]: value} : item
        ));
    };

    const handleQuantityDelete = (itemId, newQuantity) => {
        setInputFieldsVisible(prev => ({...prev, [itemId]: false}));
        setOrderQuantities(prev => prev.filter(item => item.itemId !== itemId));
    };

    const handleCostPriceChange = (itemId, newCostPrice) => {
        setOrderQuantities(prev => prev.map(item =>
            item.itemId === itemId ? {...item, costPrice: newCostPrice ?? 0, retailPrice: newCostPrice ?? 0} : item
        ));
    };

    const handleVendorSearch = (value) => {
        setSearchVendorText(value);
        const filteredOptions = vendorOptions.filter(option =>
            option.label.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredVendorOptions(filteredOptions);
    };

    // Update orders
    const handleEditOrderFieldChange = (itemId, field, value) => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) =>
                item.itemId === itemId ? {...item, [field]: value} : item
            )
        );
    };

    const deleteSelected = () => {
        setSelectedOrderDetails(prevDetails =>
            prevDetails.filter(item => !selectedRowKeys.includes(item.itemId))
        );
        setSelectedRowKeys([]); // Clear the selection
    };

    const arriveAllSelected = () => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) =>
                selectedRowKeys.includes(item.itemId)
                    ? {...item, progress: item.orderQuantity}
                    : item
            )
        );
    };

    const saveNewOrderInfo = () => {
        setEditingOrderRow(null);
    };

    const cancelEditOrder = (itemId) => {
        setSelectedOrderDetails((prevDetails) =>
            prevDetails.map((item) =>
                item.itemId === itemId ? {...item, ...originalOrderData[itemId]} : item
            )
        );
        setEditingOrderRow(null);
    };

    const editOrderRow = (itemId) => {
        const item = selectedOrderDetails.find((item) => item.itemId === itemId);
        setOriginalOrderData((prevData) => ({
            ...prevData,
            [itemId]: {
                progress: item.progress,
                costPrice: item.costPrice,
                retailPrice: item.retailPrice,
                orderQuantity: item.orderQuantity,
                vendor: item.vendor,
                memo: item.memo,
                arrivalDate: item.arrivalDate,
                deletable: item.deletable
            }
        }));
        setEditingOrderRow(itemId);
    };

    const hasProgressGreaterOrEqualOne = (orderNumber) => {
        const order = orderData.find(order => order.orderNumber === orderNumber);
        return order && order.orderDetails.some(item => item.progress >= 1);
    };

    const updateMaterialDeletable = (orderDetails) => {
        return orderDetails.map(detail => ({
            ...detail,
            deletable: detail.progress <= 0
        }));
    };

    const onSelectRowKeysChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);

        // Check if any of the selected rows have progress greater than 1
        const hasProgressGreaterThanOne = newSelectedRowKeys.some(key => {
            const selectedRow = selectedOrderDetails.find(item => item.itemId === key);
            return selectedRow && selectedRow.progress > 1;
        });

        // Check if all rows are selected
        const allRowsSelected = selectedOrderDetails.length === newSelectedRowKeys.length;

        // Disable delete button if any row has progress greater than 1 or all rows are selected
        setIsDeleteMaterialDisabled(hasProgressGreaterThanOne || allRowsSelected);
    };

    const getTotalSelectedPrice = () => {
        const total = selectedRowKeys.reduce((total, key) => {
            const item = selectedOrderDetails.find((detail) => detail.itemId === key);
            return total + (item.orderQuantity * item.retailPrice);
        }, 0);

        return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total);
    };

    const orderRowSelection = {
        selectedRowKeys,
        columnWidth: 50,
        onChange: onSelectRowKeysChange,
        getCheckboxProps: (record) => ({
            disabled: isEditingOrderRow,
        }),
    };

    const searchMethod = userInfo.os === 'MacOS' ? (
        <>
            <img src={commandIcon} alt="Command" style={{width: '20px', verticalAlign: 'middle'}}/>
            +<img src={fIcon} alt="F key" style={{width: '20px', verticalAlign: 'middle'}}/>
        </>
    ) : (
        <>
            <img src={ctrlIcon} alt="Ctrl" style={{width: '20px', verticalAlign: 'middle'}}/>
            +<img src={fIcon} alt="F key" style={{width: '20px', verticalAlign: 'middle'}}/>
        </>
    );


    useEffect(() => {
        fetchInventoryData();
    }, []);


    const handleMenuClick = (e) => {
        switch (e.key) {
            case "dashboard":
                navigate('/dashboard');
                break;
            case "projects":
                navigate('/project');
                break;
            case "inventory":
                break;
            case "accounting":
                warningMessage(intl.formatMessage({id: "main.underDeveloping"}))
                // navigate('/accounting');
                break;
            case "settings":
                navigate('/settings');
                break;
            default:
            // Handle other cases or do nothing
        }
    };

    const handleChangeType = (value) => {
        setSelectMaterialType(value);

        if (tableRef.current) {
            tableRef.current.scrollTo({
                index: 0
            });
        }
    };

    const handleChangeOrderMaterialType = (value) => {
        setSelectOrderMaterialType(value);

        if (orderTableRef.current) {
            orderTableRef.current.scrollTo({
                index: 0
            });
        }
    };

    const handleSetOrderTableDetail = (orderNumber) => {
        const order = orderData.find(order => order.orderNumber === orderNumber);
        if (order) {
            setSelectedOrderDetails(order.orderDetails);
            setSelectedOrderNumber(orderNumber);
        }
    };

    const fetchOrderData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: 'inventory',
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsFetchingData(false);
            if (response.data.status === "success") {
                const orderInfo = response.data.responseData;
                setOrderData(orderInfo);
            } else {
                errorMessage("Failed to fetch projects:\n", response.data.responseData.errorMessage);
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsFetchingData(false);
            errorMessage("Error fetching projects:", error);
            console.error("Error fetching projects:", error);
        });
    };

    const fetchInventoryData = () => {
        setIsFetchingData(true);
        axios.post(
            "/api/fetch_inventory/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    authKey: userInfo.authKey,
                    datetime: new Date()
                },
                requestName: 'fetch_inventory',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            if (response.data.status === "success") {
                const inventoryData = response.data.responseData;
                setInventoryData(inventoryData);
                setIsFetchingData(false);
            } else {
                console.error("Failed to fetch projects:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            console.error("Error fetching projects:", error);
        });
    };

    const handleOrderSubmit = () => {
        const filteredOrderQuantities = orderQuantities.filter(item => item.orderQuantity > 0);
        if (filteredOrderQuantities.length === 0) {
            warningMessage(<FormattedMessage id="projectPage.submitOrder.empty"/>);
            return;
        }

        setIsSubmittingOrder(true);
        axios.post(
            "/api/submit_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: 'inventory',
                    datetime: new Date(),
                    orderNumber: orderNumber,
                    authKey: userInfo.authKey,
                    orderDetails: filteredOrderQuantities
                },
                requestName: 'submit_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsSubmittingOrder(false);
            if (response.data.status === "success") {
                setShowSubmitOrderModal(false);
                setTimeout(() => {
                    setOrderQuantities([]);
                    setInputFieldsVisible({});
                    handleChangeType('Elevator Parts');
                    successMessage(intl.formatMessage({id: "projectPage.submitOrder.success"}, {orderNumber: orderNumber}));
                }, 300);
            } else {
                errorMessage("Failed to submit order:\n", response.data.responseData.errorMessage);
                console.error("Failed to submit order:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsSubmittingOrder(false);
            errorMessage("Error submitting order:", error);
            console.error("Error submitting order:", error);
        });
    };

    const handleOrderUpdate = () => {
        setIsUpdatingOrder(true);
        const updatedOrderDetails = updateMaterialDeletable(selectedOrderDetails);

        axios.post(
            "/api/update_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    projectId: "inventory",
                    orderNumber: selectedOrderNumber,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                    orderDetails: updatedOrderDetails
                },
                requestName: 'update_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsUpdatingOrder(false);
            if (response.data.status === "success") {
                fetchInventoryData();
                const orderCompleted = response.data.responseData.orderCompleted;
                setOrderData(prevOrderData =>
                    prevOrderData.map(order =>
                        order.orderNumber === selectedOrderNumber ? {...order, complete: orderCompleted, orderDetails: updatedOrderDetails} : order
                    )
                );
                setShowOrderTableEditModal(false);
                setTimeout(() => {
                    setEditingOrderRow(null);  // Disable number input
                    setSelectedRowKeys([]);
                    setIsEditingOrderRow(false);
                    successMessage(intl.formatMessage({id: "projectPage.updateOrder.success"}, {orderNumber: selectedOrderNumber}));
                }, 300);
            } else {
                errorMessage("Failed to update order:\n", response.data.responseData.errorMessage);
                console.error("Failed to update order:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error updating order:", error);
            console.error("Error updating order:", error);
        });
    };

    const handleOrderDelete = () => {
        setIsDeletingOrder(true);

        axios.post(
            "/api/delete_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    orderNumber: selectedOrderNumber,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                },
                requestName: 'delete_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }
        ).then(response => {
            setIsDeletingOrder(false);
            if (response.data.status === "success") {
                setOrderData(prevOrderData => prevOrderData.filter(order => order.orderNumber !== selectedOrderNumber));
                successMessage(intl.formatMessage({id: "projectPage.deleteOrder.success"}, {orderNumber: selectedOrderNumber}));
            } else {
                errorMessage("Failed to delete order:\n", response.data.responseData.errorMessage);
                console.error("Failed to delete order:\n", response.data.responseData.errorMessage);
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error deleting order:", error);
            console.error("Error deleting order:", error);
        });
    };

    const handleOrderExport = (tableType) => {
        let selectedData;

        if (selectedRowKeys.length > 0) {
            selectedData = selectedOrderDetails
                .filter(item => selectedRowKeys.includes(item.key))
                .map(item => ({
                    ...item,
                    totalPrice: (item.orderQuantity || 0) * (item.retailPrice || 0)
                }));
        } else {
            selectedData = selectedOrderDetails.map(item => ({
                ...item,
                totalPrice: (item.orderQuantity || 0) * (item.retailPrice || 0)
            }));
        }

        axios.post(
            "/api/export_order/",
            {
                parameters: {
                    userId: userInfo.userId,
                    os: userInfo.os,
                    orderNumber: selectedOrderNumber,
                    orderDetail: selectedData,
                    tableType: tableType,
                    authKey: userInfo.authKey,
                    datetime: new Date(),
                },
                requestName: 'export_order',
                jobId: uuidv4()
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                responseType: 'blob',
                withCredentials: true
            }
        ).then(response => {
            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `order_${selectedOrderNumber}.xlsx`);
                document.body.appendChild(link);
                link.click();
            } else {
                errorMessage("Failed to export order.");
                console.error("Failed to export order.");
            }
        }).catch(error => {
            setIsUpdatingOrder(false);
            errorMessage("Error exporting order:", error);
            console.error("Error exporting order:", error);
        });
    };

    const handleLogout = () => {
        setCookie('userInfo', '', {path: '/', expires: new Date(0)}); // Setting a past date to delete the cookie
        navigate('/login');
    };

    const successMessage = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const warningMessage = (message) => {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };


    return (
        <Layout style={{minHeight: '100vh'}}>
            {contextHolder}
            <Sider theme="light" style={{position: 'relative', height: '100vh'}} width={240}>
                <div className="sider-header" style={{padding: '16px', background: '#fff'}}>
                    <FormattedMessage id="sider.title" values={{versionNumber: userInfo.versionNumber}}/>
                </div>
                <Menu theme="light"
                      style={{borderRight: 0}}
                      items={siderMenuItems}
                      defaultSelectedKeys={['inventory']}
                      mode="inline"
                      onClick={handleMenuClick}
                />
                <div style={{position: 'absolute', bottom: 0, width: '100%', padding: '16px', borderTop: '1px solid #f0f0f0'}}>
                    <Link onClick={handleLogout} style={{display: 'flex', alignItems: 'center'}}>
                        <LogoutOutlined/>
                        <span style={{marginLeft: '8px'}}>
                            <FormattedMessage id="sider.logout"/>
                        </span>
                    </Link>
                </div>
            </Sider>
            <Layout className="site-layout" style={{minHeight: '100vh', backgroundColor: '#f0f2f5'}}>
                <Content style={{margin: '0 16px', backgroundColor: '#f0f2f5'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Title level={2}><FormattedMessage id="inventoryPage.title"/></Title>
                        <Space>
                            <Tag style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                {intl.formatMessage({id: "inventoryPage.search"}, {method: searchMethod})}
                            </Tag>
                            <DropDownMenuLanguage setLocale={setLocale}/>
                        </Space>
                    </div>
                    <Space direction="vertical" style={{width: '100%'}} gap="large">
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Flex wrap="wrap" gap="middle">
                                {Object.entries(materialTypes).map(([key, value]) => (
                                    <Button key={key}
                                            type={selectMaterialType === key ? 'primary' : 'text'}
                                            onClick={() => handleChangeType(key)}
                                            size='middle'
                                    >
                                        {value}
                                    </Button>
                                ))}
                            </Flex>
                            <Space>
                                <Button icon={<AppstoreAddOutlined/>}
                                        onClick={() => {
                                            setOrderNumber(uuidv4().split('-').pop());
                                            setShowSubmitOrderModal(true);
                                        }}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="inventoryPage.submitOrder"/></span>
                                </Button>
                                <Button icon={<HistoryOutlined/>}
                                        onClick={() => {
                                            fetchOrderData();
                                            setShowTrackOrderModal(true);
                                        }}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="inventoryPage.orderDetail"/></span>
                                </Button>
                                <Button icon={<ToolOutlined/>}
                                        onClick={() => warningMessage(intl.formatMessage({id: "main.underDeveloping"}))}
                                >
                                    <span style={{paddingLeft: '0px'}}><FormattedMessage id="inventoryPage.trackTools"/></span>
                                </Button>
                            </Space>
                        </div>
                        <Table dataSource={selectMaterialType === 'All' ? inventoryData : inventoryData.filter(item => item.type === selectMaterialType)}
                               ref={tableRef}
                               columns={fullInventoryColumns}
                               loading={isFetchingData}
                               pagination={false}
                               scroll={{y: '79vh'}}
                               size="middle"
                        />
                    </Space>
                </Content>
                <Modal title={intl.formatMessage({id: "inventoryPage.submitOrder"})}
                       centered
                       width="80%"
                       open={showSubmitOrderModal}
                       onCancel={() => {
                           setShowSubmitOrderModal(false);
                           setTimeout(() => {
                               setOrderQuantities([]);
                               setInputFieldsVisible({});
                               handleChangeOrderMaterialType('Elevator Parts');
                           }, 300);
                       }}
                       maskClosable={false}
                       footer={null}
                >
                    <div style={{maxHeight: '70vh', height: '70vh'}}>
                        <Space direction="vertical" style={{width: '100%'}} gap="large">
                            <Flex wrap="wrap" gap="middle" style={{width: '100%', justifyContent: 'space-between'}}>
                                <Flex wrap="wrap" gap="middle">
                                    {Object.entries(materialTypes).map(([key, value]) => (
                                        <Button key={key}
                                                type={selectOrderMaterialType === key ? 'primary' : 'text'}
                                                onClick={() => handleChangeOrderMaterialType(key)}
                                                size='middle'
                                        >
                                            {value}
                                        </Button>
                                    ))}
                                </Flex>
                                <Tag style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    {intl.formatMessage({id: "inventoryPage.search"}, {method: searchMethod})}
                                </Tag>
                            </Flex>
                            <Table virtual={true}
                                   dataSource={inventoryData.filter(item => item.type === selectOrderMaterialType)}
                                   ref={orderTableRef}
                                   columns={orderColumns}
                                   loading={isFetchingData}
                                   pagination={false}
                                   scroll={{x: 1300, y: tableHeight}}
                                   size="middle"
                            />
                        </Space>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Space>
                            {intl.formatMessage({id: "materialTable.orderNumber.detail"}, {orderNumber: orderNumber})}
                        </Space>
                        <div>
                            <Space>
                                <Button key="cancel"
                                        onClick={() => {
                                            setShowSubmitOrderModal(false);
                                            setTimeout(() => {
                                                setOrderQuantities([]);
                                                setInputFieldsVisible({});
                                                handleChangeOrderMaterialType('Elevator Parts');
                                            }, 300);
                                        }}
                                >
                                    <FormattedMessage id="main.cancel"/>
                                </Button>
                                <Button key="submit"
                                        type="primary"
                                        loading={isSubmittingOrder}
                                        onClick={() => {
                                            handleOrderSubmit();
                                        }}
                                >
                                    <FormattedMessage id="main.submit"/>
                                </Button>
                            </Space>
                        </div>
                    </div>
                </Modal>
                <Modal title={intl.formatMessage({id: "inventoryPage.orderDetail"})}
                       centered
                       width="80%"
                       open={showTrackOrderModal}
                       onCancel={() => {
                           setShowTrackOrderModal(false);
                           setTimeout(() => {
                           }, 300);
                       }}
                       footer={null}
                >
                    <div style={{maxHeight: '70vh', height: '70vh'}}>
                        <Table dataSource={orderData}
                               columns={orderDetailColumns}
                               loading={isFetchingData}
                               pagination={false}
                               scroll={{y: '55vh'}}
                               size="middle"
                        />
                    </div>
                </Modal>
                <Modal title={
                    <div style={{width: '100%', cursor: 'move'}}
                         onMouseOver={() => setDragDisabled(false)}
                         onMouseOut={() => setDragDisabled(true)}
                    >
                        {intl.formatMessage({id: "materialTable.editOrderNumber"}, {orderNumber: selectedOrderNumber})}
                    </div>
                }
                       centered
                       width="60%"
                       open={showOrderTableEditModal}
                       onCancel={() => {
                           setShowOrderTableEditModal(false);
                           setTimeout(() => {
                               setEditingOrderRow(null);  // Disable number input
                               setSelectedRowKeys([]);
                               setIsEditingOrderRow(false);
                               setModalPosition({x: 0, y: 0});
                           }, 300);
                       }}
                       maskClosable={false}
                       okText={intl.formatMessage({id: "main.save"})}
                       onOk={handleOrderUpdate}
                       okButtonProps={{loading: isUpdatingOrder}}
                       modalRender={(modal) => (
                           <Draggable disabled={dragDisabled}
                                      bounds={draggleRef.current ? draggleRef.current : undefined}
                                      position={modalPosition}
                                      onStop={(e, data) => {
                                          setModalPosition({x: data.x, y: data.y});
                                      }}
                           >
                               <div ref={draggleRef}>{modal}</div>
                           </Draggable>
                       )}
                >
                    <div style={{maxHeight: '70vh', height: '70vh'}}>
                        <Table dataSource={selectedOrderDetails}
                               columns={orderDetailTableColumns}
                               rowSelection={orderRowSelection}
                               loading={isFetchingData}
                               pagination={false}
                               scroll={{y: '60vh'}}
                               size="middle"
                               virtual={true}
                               footer={() => (
                                   <div style={{textAlign: 'left', paddingRight: '20px'}}>
                                       <FormattedMessage id="inventoryPage.totalPrice"/>: {getTotalSelectedPrice()}
                                   </div>
                               )}
                        />
                        <br/>
                        {selectedRowKeys.length > 0 && (
                            <div style={{display: 'flex', justifyContent: 'center', width: '100%', padding: '10px'}}>
                                <Button.Group>
                                    {isEditingOrderRow ? (
                                        <>
                                            <Button icon={<SaveOutlined/>}
                                                    style={{width: '120px'}}
                                                    onClick={() => {
                                                        saveNewOrderInfo();
                                                        setIsEditingOrderRow(false);
                                                        setSelectedRowKeys([]);
                                                    }}
                                            >
                                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.save"/></span>
                                            </Button>
                                            <Button icon={<CloseOutlined/>}
                                                    style={{width: '120px'}}
                                                    onClick={() => {
                                                        cancelEditOrder(selectedRowKeys[0]);
                                                        setIsEditingOrderRow(false);
                                                    }}
                                            >
                                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.cancel"/></span>
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button icon={<EditOutlined/>}
                                                    style={{width: '120px'}}
                                                    disabled={selectedRowKeys.length > 1}
                                                    onClick={() => {
                                                        editOrderRow(selectedRowKeys[0]);
                                                        setIsEditingOrderRow(true);
                                                    }}
                                            >
                                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.edit"/></span>
                                            </Button>
                                            <Button icon={<CheckOutlined/>}
                                                    style={{width: '120px'}}
                                                    onClick={arriveAllSelected}
                                            >
                                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="materialTable.complete"/></span>
                                            </Button>
                                            <Button icon={<ExportOutlined/>}
                                                    style={{width: '120px'}}
                                                    onClick={() => handleOrderExport('edit')}
                                            >
                                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.export"/></span>
                                            </Button>
                                            <Button icon={<DeleteOutlined/>}
                                                    style={{width: '120px'}}
                                                    disabled={isDeleteMaterialDisabled}
                                                    onClick={deleteSelected}
                                            >
                                                <span style={{paddingLeft: '0px'}}><FormattedMessage id="main.delete"/></span>
                                            </Button>
                                        </>
                                    )}
                                </Button.Group>
                            </div>
                        )}
                    </div>
                </Modal>
            </Layout>
        </Layout>
    );
};

export default InventoryPage;
